/*
 * 업무구분: 고객
 * 화 면 명: MSPCM012M
 * 화면설명: 고객정보 입력
 * 접근권한: 일반사용자, 지점장, 지역단스텝 (U000, F110, F102)
 * 작 성 일: 2022.06.20
 * 작 성 자: 이태훈
 * 2차 작성자: sh.park2022
 */
<template>
  <ur-page-container class="msp" title="고객정보입력" :show-title="true" type="subpage" @on-header-left-click="fn_Back">
    <ur-box-container class="msp-cm-wrap" direction="column" alignV="start">

      <!-- Content 영역 ::start -->
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-info-area">
        
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="gray-box">
          <span>{{custCardVO.custNm}}({{custCardVO.knbFrno}})</span><em>|</em><span>{{custTypeNm}}</span>
        </ur-box-container>

        <!-- 직업 영역 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="info-box pb0">
          <div class="info-title-wrap mb16">
            <span class="info-title must">직업</span>
          </div>
          <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column">
            <div class="ns-radio-list">
              <mo-radio v-for="item in jobMains" v-model="selcJobMain" :key="item.value" :value="item.value" @input="fn_CheckSelectedItem(item)">{{item.text}}</mo-radio>
            </div>
          </ur-box-container>
        </ur-box-container>

        <!-- 다른 직업 찾기-->
        <ur-box-container alignV="start" componentid="ur_box_container_005" direction="column" class="info-box">
          <msp-bottom-select ref="bottomSelect" :items="jobEtcs" v-model="fn_JobEtc" underline class="ns-dropdown-sheet" placeholder="기타" bottom-title="기타" closeBtn/>
          <mo-text-field v-if="isJobSearch" v-model="fn_JobText" searchable underline placeholder="다른 직업 찾기" @keyup.enter="fn_BtnS3OnClick()" @click-icon="fn_BtnS3OnClick"/>
        </ur-box-container>

        <!-- 취득경로 ::start -->
        <ur-box-container alignV="start" componentid="ur_box_container_006" direction="column" class="info-box">
          <div class="info-title-wrap mb16">
            <span class="info-title must">취득경로</span>
          </div>
          <ur-box-container alignV="start" componentid="ur_box_container_007" direction="column">
            <div class="ns-radio-list">
              <mo-radio class="mb22" v-model="entryPath" value="acutnCustYn" @input="fn_ClickEntryPath" :disabled="disabledYN.entryDisabd">지인</mo-radio>
              <mo-radio class="mb22" v-model="entryPath" value="intdr" @input="fn_ClickEntryPath" :disabled="disabledYN.entryDisabd">소개</mo-radio>

              <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-sch-box" v-if="entryPath === 'intdr'">
                <div class="ns-date-picker multi">
                  <!-- 소개자 검색-->
                  <mo-text-field v-model="custCardVO.custNm01" searchable underline placeholder="소개자 검색" :disabled="disabledYN.entryDisabd" 
                    @click-icon="fn_SelIntdr" @focus="fn_IsNextBtnVisible(false, 'custCardVO.intdrLb')" @blur="fn_BlurIntdr"/><span></span>
                  <!-- 소개일-->
                  <mo-date-picker v-model="custCardVO.introYmd" placeholder="소개일" :max-date="today" :disabled="disabledYN.entryDisabd"/>
                </div>
              </ur-box-container>

              <mo-radio class="mb22" v-model="entryPath" value="aptCustYn" @input="fn_ClickEntryPath" :disabled="disabledYN.entryDisabd">개척</mo-radio>
              <mo-radio class="mb22" v-model="entryPath" value="rspnDstrtCustYn" @input="fn_ClickEntryPath" :disabled="disabledYN.entryDisabd">기타</mo-radio>
            </div>
          </ur-box-container>
        </ur-box-container>
        <!-- 취득경로 ::end -->

        <!-- 세대원동의진행 영역 ::start -->
        <ur-box-container alignV="center" componentid="" direction="column" class="info-box">
          <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
            <span class="info-title">세대원동의진행</span>
            <mo-button color="normal" shape="border" size="medium" class="blue" @click="fn_ConfirmAddHoshOpen">세대추가</mo-button>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-contract-list type-1">
            <mo-list :list-data="iCHoshInfoList">
              <template #list-item="{ item }">
                <!--//START 레이블이 다른 TYPE -->
                <mo-list-item ref="bs01" mode="none" class="closebtn mb10" v-if="item.mnCustRltnCd !== '01'">
                  <ur-box-container alignV="start" direction="column" class="list-item__contents">
                    <ur-box-container alignH="between" direction="row" class="list-item__contents__title">
                      <span class="name txtSkip">{{ item.custNm }}</span>
                      <div class="ns-btn-close" name="닫기" @click="fn_ConfirmOpen2(item)"></div>
                    </ur-box-container>
                    <ur-box-container alignH="start" direction="column" class="list-item__contents__info">
                      <div class="txt_box">
                        <div>
                          <small>관계</small><span>{{ $cmUtil.getCustRltnNm(item.mnCustRltnCd) }}</span><em>|</em>
                          <small>생년월일</small><span>{{ $commonUtil.dateDashFormat(item.ralDob) }}</span>
                        </div>
                        <div v-if="item.isForeigner">
                          <small>여권번호</small><span>{{ item.psprtNo }}</span><em>|</em>
                          <small>국적</small><span>{{ item.bpNatyNm }}</span>
                        </div>
                      </div>
                    </ur-box-container>
                  </ur-box-container>
                </mo-list-item>
                <!--//END 레이블이 다른 TYPE -->
              </template>
            </mo-list>
          </ur-box-container>  
        </ur-box-container>
        <!-- 세대원동의진행 영역 ::end -->

        <!-- 주소 영역 -->
        <ur-box-container alignV="center" componentid="" direction="column" class="info-box">
          <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
            <span class="info-title">주소</span>
            <mo-button color="normal" shape="border" size="medium" class="blue" @click="fn_OpenMSPCM014P">
              <!-- 자택/직장 주소 모두 있을때 수정버튼 -->
              {{ !$bizUtil.isEmpty(custCardVO.homAddr) && !$bizUtil.isEmpty(custCardVO.jobpAddr) ? '수정' : '입력' }}
              </mo-button>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-contract-list type-1">
            <mo-list-item ref="bs01" mode="none" class="closebtn">
              <ur-box-container alignV="start" direction="column"  class="list-item__contents">
                <ur-box-container alignH="between" direction="row" class="list-item__contents__title">
                  <span class="name addr">자택</span>
                  <div class="ns-btn-close" name="닫기" v-if="!$bizUtil.isEmpty(custCardVO.homAddr)" 
                        @click="fn_ConfirmOpen3('home')"/>
                </ur-box-container>
                <ur-box-container alignH="start" direction="column" class="list-item__contents__info">
                  <div class="info_box inflex">
                    <span class="address">
                      {{custCardVO.homFrnoPstcd}}
                      {{custCardVO.homAddr}} 
                      {{custCardVO.bldgNo01}} 
                      {{custCardVO.homDtlad}} 
                      {{custCardVO.adtnAddr01}} 
                    </span>
                  </div>
                </ur-box-container>
              </ur-box-container>
            </mo-list-item>
            <mo-list-item ref="bs01" mode="none" class="closebtn">
              <ur-box-container alignV="start" direction="column"  class="list-item__contents">
                <ur-box-container alignH="between" direction="row" class="list-item__contents__title">
                  <span class="name addr">직장</span>
                  <div class="ns-btn-close" name="닫기" v-if="!$bizUtil.isEmpty(custCardVO.jobpAddr)" 
                        @click="fn_ConfirmOpen3('job')" />
                </ur-box-container>
                <ur-box-container alignH="start" direction="column" class="list-item__contents__info">
                  <div class="info_box inflex">
                    <span class="address">
                      {{custCardVO.jobpFrnoPstcd}}
                      {{custCardVO.jobpAddr}}
                      {{custCardVO.bldgNo02}}
                      {{custCardVO.jobpDtlad}}
                      {{custCardVO.adtnAddr02}}
                    </span>
                  </div>
                </ur-box-container>
              </ur-box-container>
            </mo-list-item>

          </ur-box-container>  
        </ur-box-container>
        <!-- 주소 영역 -->

        <!-- 외국인정보 영역 ::start -->
        <ur-box-container v-if="isForeigner" alignV="center" componentid="" direction="column" class="info-box mb80">
          <ur-box-container alignH="between" direction="row" class="info-title-wrap mb10">
            <span class="info-title">외국인정보</span>
            <mo-button color="normal" shape="border" size="medium" class="blue" @click="fn_BtnCntryCodeClick">
            {{ !$bizUtil.isEmpty(custCardVO.bpPsprtNo) && !$bizUtil.isEmpty(custCardVO.bpNatyNm) ? '수정' : '입력' }}
            </mo-button>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="column" class="ns-contract-list type-1">
            <mo-list :list-data="lv_CustForigInfo">
              <template #list-item="{item}">
                <mo-list-item>
                  <div class="list-item__contents">
                    <div class="list-item__contents__info">
                      <div class="txt_box">
                        <div>
                          <small>여권번호</small><span> {{ item.bpPsprtNo }}</span><br>
                          <small>국적</small><span> {{ item.bpNatyNm }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </mo-list-item>
              </template>
            </mo-list>
          </ur-box-container>  
        </ur-box-container>
        <!-- 외국인정보 영역 ::end -->

        <!-- BottomSheet 영역 ::start -->
        <!-- 저장 전 동의 안내 bottomSheet -->
        <mo-bottom-sheet ref="bottomSheetSaveBfCnfrm" class="ns-bottom-sheet ns-style3">
          <div class="customer-info-txt txt-center">
            <p class="cancel-ment pt0 left">
              1. 계약자가 동의한 경우에만 변경 가능합니다.<br>계약자의 동의를 받으셨습니까?<br>
              2. 지금 입력하신 정보는 삼성생명 온라인에 반영되며 변경 내용이 고객님께 안내(SMS, e-Mail) 됩니다.<br>
              반영하시겠습니까?<br>
              (취소시 이전데이타 복구)<br>
              ※ 동의 없이 변경할 경우 민원발생 소지가 있으니 주의 바랍니다.
            </p>
          </div>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseBottomSheet('saveBfCnfrmPop')">아니요</mo-button>
                <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SaveBfCnfrmPop('Save')">예</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
            
        <!-- 종료 안내 bottomSheet -->
        <mo-bottom-sheet ref="bottomSheetBackCnfrm" class="ns-bottom-sheet ns-style3">
          <div class="customer-info-txt txt-center">
            <p class="cancel-ment pt0">
              고객동의를 종료하시겠습니까?<br/> 동의종료 선택시 작성된 고객정보는 저장되지 않으며, 고객동의는 완료되지 않습니다.
            </p>
          </div>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseBottomSheet('backCnfrmPop')">취소</mo-button>
                <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CloseBackCnfrmPop('Close')">나가기</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>

        <!-- 세대원 삭제 안내 bottomSheet -->
        <mo-bottom-sheet ref="refDeltHoshConf" class="ns-bottom-sheet ns-style3">
          <div class="customer-info-txt txt-center">
            <p class="cancel-ment pt0">
              선택한 세대원을 제외할까요?<br>(해당 세대원의 동의만 진행되지않을뿐<br>고객카드는 삭제되지 않습니다)
            </p>
          </div>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseBottomSheet('hoshPop')">취소</mo-button>
                <mo-button componentid="mo_button_006" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_DelHosh">삭제</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>

        <!-- 국가정보 입력 권유 bottomSheet -->
        <mo-bottom-sheet ref="bottomSheetCntryInfo" class="ns-bottom-sheet ns-style3">
          <div class="customer-info-txt txt-center">
            <p class="cancel-ment pt0">
              외국인 고객이시네요.<br>외국인 정보를 추가 입력하시겠습니까?
            </p>
          </div>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_012" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_007" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseBottomSheet('cntryPop')">다음에 하기</mo-button>
                <mo-button componentid="mo_button_008" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_CntryInfoPop('Add')">추가</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>

        <!-- NICE 인증 결과 안내 bottomSheet -->
        <mo-bottom-sheet ref="niceAuthPop" class="ns-bottom-sheet ns-style3">
          <div class="customer-info-txt txt-center">
            <p class="cancel-ment pt0" v-html="niceAuthResultMsg"></p>
          </div>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_NiceAuthPop('Confirm')">확인</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>

        <!-- 주소 삭제 Confirm bottomSheet -->
        <mo-bottom-sheet ref="refDeltAddrPop" class="ns-bottom-sheet ns-style3">
          <div class="customer-info-txt txt-center">
            <p class="cancel-ment pt0">선택한 주소를 삭제할까요?</p>
          </div>
          <template v-slot:action>
            <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-btn-relative">
              <div class="relative-div">
                <mo-button componentid="mo_button_007" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_CloseBottomSheet('addrPop')">취소</mo-button>
                <mo-button componentid="mo_button_005" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_DelAddr(deleteAddrType)">확인</mo-button>
              </div>
            </ur-box-container>
          </template>
        </mo-bottom-sheet>
        <!-- BottomSheet ::end -->

      </ur-box-container>
      <!-- Content 영역 ::end -->

      <div class="ns-height40"></div>
      <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-btn-relative">
        <div class="relative-div">
          <!-- 완료 버튼-->
          <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" 
                  v-if="ShowBottomBtn" @click="fn_CallCmplt('NEXT')" >완료</mo-button>
        </div>
      </ur-box-container>
    </ur-box-container>
  </ur-page-container>
</template>

<script>
/************************************************************************************************
* 공통 라이브러리 INCLUDE 영역
************************************************************************************************/
import moment from 'moment'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue' /* dropdown bottom-sheet */
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import Msg from '@/systems/webkit/msg/msg'

import MSPCM013P from '@/ui/cm/MSPCM013P' // 보험직업조회
import MSPCM014P from '@/ui/cm/MSPCM014P' // 주소입력 (추가정보입력)
import MSPCM015P from '@/ui/cm/MSPCM015P' // 외국인 등록정보 
import MSPCM016P from '@/ui/cm/MSPCM016P' // 세대원 입력
import MSPCM019M from '@/ui/cm/MSPCM019M' // 동의결과처리 확인 (완료)
import MSPCM019P from '@/ui/cm/MSPCM019P' // 신분증진위여부
import MSPCM021P from '@/ui/cm/MSPCM021P' // 고객 중복조회
import MSPCM129P from '@/ui/cm/MSPCM129P' // 소개자 고객조회

export default {
  /************************************************************************************************
  * Vue 파일 속성 정의 영역
  ************************************************************************************************/
  // 현재 화면명
  name: 'MSPCM012M',
  // 현재 화면 ID
  screenId: 'MSPCM012M',
  // 컴포넌트 선언
  components: {
    moment,
    MspBottomSelect,
    MSPBottomAlert,
    MSPCM013P,
    MSPCM014P,
    MSPCM015P,
    MSPCM016P,
    MSPCM019M,
    MSPCM019P,
    MSPCM021P,
    MSPCM129P
  },
 
  /************************************************************************************************
  * 화면 전역 변수 선언 영역
  ************************************************************************************************/
  data () {
    return {
      stndKeyList: [],
      iCHoshInfoList: [], // 화면에 보여질 세대원 리스트
      selectHoshItem: {}, // 삭제를 위한 선택된 세대원

      title: '고객정보 입력 메인 화면', // 화면명
      today: '',
      custTypeNm: '신규', // 신규 or 기존 구분
      searchJobcdNm: '', // 검색직업명
      isKnbBknoModified: false, //주민번호 수정여부
      chkKnbResult: true, // 주민번호 유효성 검증 결과
      knbErrorMsg: '', // 주민번호 유효성 체크 후 에러 메시지
      agreeTypeMcnstList: {}, // 필수 컨설팅 리스트
      agreeTypeMktList: {}, // 마케팅 동의 리스트
      infoPrcusCnsntTypCd: '', // 정보활용동의 유형 코드
      selcAgreeTypeMcnst: false, // 필수컨설팅동의 선택여부
      selcAgreeTypeMkt: false, // 마케팅동의 선택여부
      intrResult: true, // 소개자 유효성 체크 결과
      isForeigner: false, // 외국인 체크 여부
      chkRrn: false, // 미확보 체크여부

      //msg 
      newCustSaveCnfrmMsg : '', //신규 고객 저장시 고객정보입력확인 메시지
      niceAuthResultMsg: '', // 나이스인증결과 메시지


      /* 기본값 설정 */
      selcJobMain: '', //선택한 직업메인
      jobEtc: {value: '0', text: '그 외 직업'}, //직업 기타
      entryPath : '', //경로

      //팝업 오픈 여부
      useMSPCM013P: false, //직업팝업
      useMSPCM014P: false, //주소검색

      //팝업 연동
      popup013: {}, // MSPCM013P 보험직업조회 팝업
      popup014: {}, // MSPCM014P 추가정보입력 (집, 회사 선택)
      popup015: {}, // MSPCM015P 외국인 등록정보 등록 팝업
      popup016: {}, // MSPCM016P 세대원 입력
      popup021: {}, // MSPCM021P 고객 중복조회 팝업
      popup129: {}, // MSPCM129P 소개자 고객조회 팝업
      popup019: {}, // MSPCM019P 외국인신분증진위여부


      // 주고객 정보 VO
      custCardVO: {
        customerType: 'new', // 주고객이 기존, 신규인지 여부
        custNm: '', // 이름
        custNmOrigin: '', // 조회된 실제 고객명
        custNmPrev: '', // 원본 이름(이름명 변경시 체크)
        isCustNmChanged: false, // 고객명 변경 여부
        custId: '', // 사용자ID
        chnlCstgrId: '', // 채널그룹ID
        chnlCustId: '', // 채널 고객 ID
        agreeTypeMcnst: false, // 필수컨설팅 체크
        agreeTypeMkt: false, // 마케팅동의 체크
        cnsltNo: '', // 컨설턴트번호
        knb: '', // 전체주민번호
        knbBackup: '', // 주민번호 백업 - TSSSP103M의 이전 버튼 선택 시, 마지막 등록된 주민번호값을 화면에 display해야 함.
        knbFrno: '', // 주민번호 앞자리
        knbBkno: '', // 주민번호 뒷자리
        knbBknoBackup: '', // 성별코드+000000 형태 주민번호 뒷자리 보관장소로 쓰임
        knbBknoCipher: '', // 암호화된 주민번호
        knbBknoCipherBackup: '', // 주민번호 뒷자리 백업(암호화 값이 들어올 경우에만 임시 보관장소로 쓰임)
        knbBkFstNo: '', // 인증 시 입력한 주민번호 뒷자리(성별구분코드)
        //isKnbChanged: false, // 주민번호 변경 여부
        samScCd: '', // 음력,양력 구분(양력 : "01"  ,  음력 : "02")
        ralDob: '', // 실제생년월일 "19800101"
        knbScCd: '', // ZPER01 - 내국인, ZPER02 - 외국인
        jobpNm: '', // 직장명
        deptNm: '', // 부서명
        jobpsCdNm: '', // 직위명
        addrStdztYn: '', // 표준화 주소 여부
        stdztAddrYn: '',
        homFrnoPstcd: '', // 자택 우편번호
        homAddr: '', // 자택 주소
        homDtlad: '', //
        bldgNo01: '', //
        adtnAddr01: '', //
        homAddrScCd: '', // 자택주소구분코드(1:지번주소, 2:도로명주소,3:국외주소)
        homAddrRefnCd: '', // 자택주소정제코드(01: 정제주소, 02:입력주소)
        homAddrStatCd: '', // 자택주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
        jobpAddr: '', // 직장 주소
        jobpFrnoPstcd: '', // 직장 우편번호
        jobpDtlad: '', // 직장주소 상세
        bldgNo02: '', //
        adtnAddr02: '', //
        jobpAddrScCd: '', // 직장주소구분코드(1:지번주소, 2:도로명주소,3:국외주소)
        jobpAddrRefnCd: '', // 직장주소정제코드(01: 정제주소, 02:입력주소)
        jobpAddrStatCd: '', // 직장주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
        isAddrChanged: false, // 주소 변경 여부
        isCelnoChanged: false,
        celno: '', // 휴대전화 전체
        celnoBackUp: '', // 휴대전화 전체 하이픈추가
        celnoFrno: {}, // 휴대전화 앞자리
        celnoBkno: '', // 휴대전화 뒷자리
        faxNo: '', //
        homTelno: '', //
        jobpTelno: '', //
        isEmailChanged: false,
        emailAddr: '', //
        emailAddrFrno: '', // 이메일 앞자리
        emailAddrBkno: '', // 이메일 뒷자리 @ 다음
        scrapYn: '', // 스크래핑진위확인 여부
        custEngNm: '', // 고객 영문명
        natyCd: '', // 국가코드
        natyNm: '', // 국가코드명
        psprtNo: '', // 여권번호
        dmclScCd: '', // 거주지구분코드
        dmclScCdNm: '', // 거주지구분코드명
        foreignPartnerNo: '', // 외국인고객번호
        bpCustEngNm: '', // BP 영문명
        bpPsprtNo: '', // BP 여권번호
        bpNatyCd: '', // BP 국적코드
        bpNatyNm: '', // BP 국적명
        bpDmclScCd: '', // BP 거주지구분코드
        bpDmclScCdNm: '', // BP 거주지구분코드명
        custPtclrMatrCntnt: '', // 고객 특이사항 내용
        mnCustRltnCd: '', // 주고객 관계코드 (01: 본인)
        rlnmCnfRsltCd: '', // 실명확인 결과코드
        chnlCstgrRltnCd: '', // 세대주 - X
        isJobChanged: false,
        jobCd: '012212', // 직업코드
        jobCdNm: '회사 사무직 종사자', // 직업명
        introYmd: '', // 소개일시
        introYmdPrev: '', // 소개일 원본 보관
        custNm01: '', // 소개자 명
        custNm01Prev: '', // 소개자 원본 이름(이름명 변경시 체크)
        intdrChnlCustId: '', // 소개자 채널 고객 ID
        acutnCustYn: 'N', // 취득경로가 지인 인 경우 Y로 세팅, 아닐 경우 N
        aptCustYn: 'N', // 취득경로가 개척인 경우 Y로 세팅, 아닐 경우 N
        rspnDstrtCustYn: 'Y', // 취득경로가 기타인 경우 Y로 세팅, 아닐 경우 N
        coalCustYn: 'N', // 제휴고객 여부일 때 Y, 아닐 경우 N
        mcnstYmd: '', // 필수 컨설팅 만료일
        mktYmd: '', // 마케팅 만료일
        chkMcnstNm: '필수컨설팅',
        chkMktNm: '마케팅',
        unAprvCustYn: 'Y', // 미승인 여부 Y:미승인, N: 승인,
        custApvrList: [], // 주고객 필컨/마케팅 동의 활용 정보 리스트
        isMobile: 'Y', // 모바일 사용여부,
        atcarInsrEntCoCd: '',
        atcarInsrEntCoCdNm: '',
        chldFgr: '',
        cndtrYn: '',
        cnvrSlctYn: '',
        covrAnlyPblYn: '',
        custNm02: '',
        dmalReciCnsntYn: '',
        emailReciCnsntYn: '',
        emailReciRjtYn: '',
        hbyCd: '',
        hbyCdNm: '',
        homPstcdSno: '',
        hosOwnYn: '',
        hoshMnYn: '',
        insrExpYmd: '',
        jobLclsfCd: '',
        jobpBknoPstcd: '',
        jobpPstcdSno: '',
        lifclCd: '',
        mailRcpplScCd: '',
        mailRcpplScCdNm: '',
        margMemrYmd: '',
        margScCd: '',
        margScCdNm: '',
        iCHoshInfoListInqrVO: [], // 세대원 리스트
        tabItemList: [],
        iCCustInfoPrcusCnsntVO: {}, // 정보활용동의 내역 객체
        emailStatFlag: '', // 이메일 주소값 변경 여부 - 변경이 있는 경우 X로 바뀜
        backCount: 0,
        trtSystmPathDtlVal: '', // 처리시스템경로상세값 (EP에서 화면ID로 요청)
        busnTrtPathCd: '', // 업무처리경로코드 (태블릿_사랑온고객관리 : 2220)
        trtSystmPathCd: '', // 처리시스템경로코드 (사랑온 고정 구분값 : 22)
        infoPrcusCnsntTypCd: '', // 정보활용동의유형코드
        infoPrcusCnsntStatCd: '', // 정보활용동의상태코드
        infoPrcusCnsntPathCd: '', // 정보활용동의경로코드 (사랑On미니 )
        mktCnvsMthTelYn: 'N',
        mktCnvsMthSmsYn: 'N',
        mktCnvsMthEmailYn: 'N', // //마케팅권유방법이메일여부
        mktCnvsMthPstYn: 'N', // 마케팅권유방법우편여부
        mktCnsntAvlPrd: '', // 마케팅동의유효기간
        athntHstrId: '', // 카카오톡인증이력ID
        lmsDspchRsltId: '' // LMS발송결과ID
      },
      // 주고객 정보 Copy VO
      custCardCopyVO: {},
      // 고객 조회용 Service IF VO
      custCardRegMdfcVO: {},
      // 주고객 조회용 Service IF VO
      mnCustCardRegMdfcVO: {},
      // 기존 세대원 리스트
      existHoshInfoListInqrVO: [],
      // 신규 세대원 리스트
      newHoshInfoListInqrVO: [],
      // 기존 세대원 저장용 리스트
      saveExistHoshInfoListInqrVO: [],
      // 신규 세대원 저장용 리스트
      saveNewHoshInfoListInqrVO: [],
      hoshInfoVO: {
        customerType: 'new', // 주고객이 기존, 신규인지 여부
        selcAgreeTypeMkt: '', // 주고객 마케팅 동의여부
        selcAgreeTypeMcnst: '', // 주고객 필컨 동의여부
        cnsltNo: '', // 컨설턴트번호
        chnlCustId: '', // 채널 고객ID
        trtrEno: '', // 처리자사번
        chnlCstgrId: '', // 채널고객그룹ID
        chnlCustScCd: '', // 가망 임시 구분 (01:가망고객, 02:임시고객, 03:본인모집고객, 04:타인모집고객, 05:관심고객)
        agreeTypeMcnst: false, // 필수컨설팅 체크
        agreeTypeMkt: false, // 마케팅동의 체크
        isCelnoChanged: false,
        celno: '', // 휴대전화
        prcsfCd: 'I', // I:신규, U:업데이트
        celnoFrno: {}, // 휴대전화 앞자리
        celnoBkno: '', // 휴대전화 뒷자리
        isCustJobCdChanged: false,
        custJobCd: '', // 직업코드
        custJobCdNm: '', // 직업명
        custNm: '', // 이름
        custNmPrev: '', // 원본 이름(이름명 변경시 체크)
        scrapYn: '', // 스크래핑진위확인 여부
        custEngNm: '', // 고객 영문명
        natyCd: '', // 국가코드
        natyNm: '', // 국가코드명
        psprtNo: '', // 여권번호
        dmclScCd: '', // 거주지구분코드
        dmclScCdNm: '', // 거주지구분코드명
        foreignPartnerNo: '', // 외국인고객번호
        bpCustEngNm: '', // BP 영문명
        bpPsprtNo: '', // BP 여권번호
        bpNatyCd: '', // BP 국적코드
        bpNatyNm: '', // BP 국적명
        bpDmclScCd: '', // BP 거주지구분코드
        bpDmclScCdNm: '', // BP 거주지구분코드명
        isCustNmChanged: false, // 고객명 변경 여부
        knb: '', // 전체주민번호
        knbBackup: '', // 전체주민번호 백업 - TSSSP103M의 이전 버튼 선택 시, 마지막 등록된 주민번호값을 화면에 display해야 함.
        knbFrno: '', // 주민번호 앞자리
        knbBkno: '', // 주민번호 뒷자리
        knbBknoCipher: '', // 주민번호 뒷자리 암호화
        knbBknoCipherBackup: '', // 주민번호 뒷자리 백업(암호화 값이 들어올 경우에만 임시 보관장소로 쓰임)
        knbBkFstNo: '', // 주민번호 뒷자리 성별구분코드
        //isKnbChanged: false, // 주민번호 변경 여부
        sxdsCd: '', // 성별
        dob: '', // 생년월일
        knbScCd: 'ZPER01', // 국적구분코드 (ZPER01: 내국인, ZPER02: 외국인)
        unAprvCustYn: 'Y', // 미승인 여부 Y:미승인, N: 승인,
        mnCustRltnCd: '03', // 주고객관계코드
        ralBrthd: '', // 실제 생년월일
        samScCd: '', // 음력,양력 구분
        rlnmCnfRsltCd: '', // 실명확인결과코드
        hoshMnYn: '', // 세대주구분
        mcnstYmd: '', // 필수 컨설팅 만료일
        mktYmd: '', // 마케팅 만료일,
        isPossibleFetus: true, // 태아 추가 가능 여부
        isNew: true, // 신규 세대원인지 여부
        searchFlag: '', // 고객카드조회 여부
        // isDupCust: false, // 고객카드중복여부
        isMobile: 'Y', // 모바일 사용여부
        iCCustInfoPrcusCnsntVO: {}, // 정보활용동의 내역 객체
        //isSaveFetusAlready: false // 태아를 이미 저장했는지 여부 체크(TSSSP103M에서 이전버튼 클릭 후, 다시 다음 버튼 누를 때, 태아를 중복해서 저장하는 것을 막기 위해)
      },
      // 신규 세대원 추가시 임시 고객 채널 ID생성
      newChannelId: 0,
      disabledObj: {
        chkForeignerDisabled: false,
        mcnstDisabled: false,
        mktDisabled: false,
        knbFrnoDisabled: false,
        knbBknoDisabled: false,
        addrTypeDisabled: false,
        zipBtnDisabled: false,
        entryDisabled: false,
        celnoFrnoDisabled: false,
        celnoBknoDisabled: false,
        emailDisabled: false,
        emailBknoDisabled: false,
        jobDisabled: false,
        nextBtnDisabled: false // 다음버튼 비활성화 여부
      },
      disabledYN: {
        knbFrnoDisabd: true,
        knbBkFstNo: true,
        custNmDisabd: true,
        celnoFrnoDisabd: true,
        celnoBknoDisabd: true,
        knbBkFstNoDisabd: true,
        isForeignerDiabd: true,
        cntryCodeDisabd: true,
        entryDisabd: false
      },
      totalChildValidationCount: 0, // 세대원 카드 유효성 전체 체크갯수
      currentChildValidationCount: 0, // 세대원 카드 유효성 현재 체크갯수
      isChildValidationResult: true, // 세대원 카드 유효성 결과
      isJobSearch: false, // 다른직업 찾기 영역
      isBackBtn: false, //backBtn 여부
      btnCaller: '', // NEXT(다음버튼), ADD(세대추가 버튼) - 버튼 클릭 시 btnCaller의 값에 매핑됨
      isNextBtnVisible: true, // 다음 버튼 보이게 할지 여부 처리 변수
      ShowBottomBtn:true,
      focusRefName: '', // 텍스트필드의 입력 시 포커스 된 label 명
      searchRsltMsg: '신규고객', // 고객카드조회 결과 메시지
      isConfirmAddHosh: false,
      isConfirmAddHoshOnly: false,
      lv_msgAddHoshOnly: '',
      /* 공통 코드  */
      jobMains: [
        {
          value: '012212',
          text: '회사 사무직 종사자'
        },
        {
          value: '113200',
          text: '전업주부'
        },
        {
          value: '112201',
          text: '대학(원)생'
        }
      ],
      jobEtcs: [
        {value: '112200', text: '초,중,고등학생'},
        {value: '116100', text: '미취학아동'},
        {value: '113302', text: '61세이상 남자 및 여자'},
        {value: '042302', text: '보험설계사(당사)'},
        {value: '053130', text: '식당 종사원'},
        {value: '000210', text: '기업 대표이사 및 고위임원'},
        {value: '052101', text: '주방장 및 조리사'},
        {value: '0', text: '그 외 직업'}
      ],
      deleteAddrType: '', // 삭제를 위한 선택된 주소타입
      addrType: '',
      addrTypes: [
        {
          value: '11',
          text: '자택'
        },
        {
          value: '12',
          text: '직장'
        }
      ],
      entryPaths: [
        {
          value: 'acutnCustYn',
          text: '지인'
        },
        {
          value: 'intdr',
          text: '소개'
        },
        {
          value: 'aptCustYn',
          text: '개척'
        },
        {
          value: 'rspnDstrtCustYn',
          text: '기타'
        }
      ],
      lv_CustForigInfo: [], // 외국인 정보
    }
  },

  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  /** computed 함수 정의 영역 */
  computed: {
    fn_JobEtc: { // 직업 기타 셀렉트 박스 선택 시
      get: function () {
        return this.jobEtc
      },
      set: function (data) {
        this.jobEtc = data
        if (data === '0') { // 셀렉트박스의 '그 외' 선택 시 고객카드 VO의 값을 모두 초기화 함
          this.custCardVO.jobCd = ''
          this.custCardVO.jobCdNm = ''
          this.isJobSearch = true //다른직업찾기 영역 활성화
        } else {
          this.custCardVO.jobCd = data

          let jobEtcNm = this.jobEtcs.filter((val) => {
            if(val.value === data){
              return val
            }
          })
          this.custCardVO.jobCd = jobEtcNm[0].value
          this.custCardVO.jobCdNm = jobEtcNm[0].text
          this.isJobSearch = false //다른직업찾기 영역 비활성화
        }
        this.selcJobMain = '' // 직업 메인 버튼 리스트를 초기화 함
      }
    },
    fn_JobText: {
      get: function () {
        let lv_Txt = this.custCardVO.jobCdNm // this.$bizUtil.omitStr(this.custCardVO.jobCdNm, 7)
        return lv_Txt
      },
      set: function (data) {
        this.searchJobcdNm = data
      }
    },
    fn_NextBtnMargin () { // '다음' 버튼 안보이게 처리 할 때, 바닥 영역의 margin-bottom을 0으로 처리. - 안드로이드 키보드가 올라올 경우 '다음'버튼 영역의 공간을 아예 없애버림.
      if (!this.isNextBtnVisible) {
        return 'margin-bottom:0'
      }
    },

  },
  
  /** watch 정의 영역 */
  watch: {
    'custCardVO.custNm01' () { // 소개자를 clearable로 한꺼번에 지울경우 computed의 validation 함수를 탈수 있도록 추가 조치함.
      if (this.entryPath === 'intdr' && this.custCardVO.custNm01.trim() === '') {
        this.intrResult = false // 소개자 조회
        this.custCardVO.intdrChnlCustId = ''
      }
    },
    entryPath () {
      if(this.entryPath !== 'intdr'){
        //소개가 아닐 시 초기화
        this.custCardVO.custNm01 = '' // 소개자명
        this.custCardVO.intdrChnlCustId = '' // 채널 고객ID
        this.custCardVO.custNm01Prev = ''
        this.intrResult = false
      }

    }

  },

  /** 설명 : Step-2 인스턴스가 작성된 후 동기적 호출 */
  created () {
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_Back) // listen to Event
    
    let lv_Vm = this

    // 오늘 날짜 조회
    lv_Vm.$commonUtil.getServerTime().then(function (response) {
      lv_Vm.today = moment(response).format('YYYY-MM-DD')
    })

    if(lv_Vm.$route.params !== undefined ){
      lv_Vm.agreeTypeMcnstList = lv_Vm.$route.params.agreeTypeMcnstList // 필수 컨설팅 리스트
      lv_Vm.agreeTypeMktList = lv_Vm.$route.params.agreeTypeMktList // 마케팅 동의 리스트
      lv_Vm.infoPrcusCnsntTypCd = lv_Vm.$route.params.infoPrcusCnsntTypCd // 정보활용동의 유형 코드

       // 선택한 동의유형 셋팅
      if (lv_Vm.infoPrcusCnsntTypCd !== null && lv_Vm.infoPrcusCnsntTypCd !== undefined && lv_Vm.infoPrcusCnsntTypCd !== '') {
        if (lv_Vm.infoPrcusCnsntTypCd === '17') {
          lv_Vm.selcAgreeTypeMcnst = true
        } else if (lv_Vm.infoPrcusCnsntTypCd === '19') {
          lv_Vm.selcAgreeTypeMkt = true
        } else {
          lv_Vm.selcAgreeTypeMcnst = true
          lv_Vm.selcAgreeTypeMkt = true
        }
      }


      if (lv_Vm.$route.params.custCardVO !== null && lv_Vm.$route.params.custCardVO !== undefined) {
        lv_Vm.custCardVO.custNm = lv_Vm.$route.params.custCardVO.custNm
        lv_Vm.custCardVO.knbFrno = lv_Vm.$route.params.custCardVO.knbFrno
        lv_Vm.custCardVO.knbBkFstNo = lv_Vm.$route.params.custCardVO.knbBkFstNo
        lv_Vm.custCardVO.knbScCd = lv_Vm.$route.params.custCardVO.knbScCd
        lv_Vm.custCardVO.knbBknoBackup = lv_Vm.custCardVO.knbBkFstNo + '000000'
        lv_Vm.custCardVO.chnlCustId = lv_Vm.$route.params.custCardVO.chnlCustId
        lv_Vm.custCardVO.isExistCustYN = lv_Vm.$route.params.custCardVO.isExistCustYN

        if (lv_Vm.custCardVO.isExistCustYN !== 'Y') {
          // 주고객관계코드 '01(본인)' 으로 세팅
          this.custCardVO.mnCustRltnCd = '01'
        }
        
        if (lv_Vm.custCardVO.knbScCd === 'ZPER02') { // 외국인
          lv_Vm.isForeigner = true
        } else {
          lv_Vm.isForeigner = false
        }
        lv_Vm.custCardVO.celnoFrno = lv_Vm.$route.params.custCardVO.celnoFrno
        lv_Vm.custCardVO.celnoBkno = lv_Vm.$route.params.custCardVO.celnoBkno

        lv_Vm.custCardVO.celno = lv_Vm.$route.params.custCardVO.celno
        lv_Vm.custCardVO.lmsDspchRsltId = (lv_Vm.$route.params.custCardVO.lmsDspchRsltId !== undefined) ? lv_Vm.$route.params.custCardVO.lmsDspchRsltId : '' // LMS발송결과ID
        lv_Vm.custCardVO.athntHstrId = (lv_Vm.$route.params.custCardVO.athntHstrId !== undefined) ? lv_Vm.$route.params.custCardVO.athntHstrId : '' // 카카오톡인증이력ID
      }

      // 기존고객 여부를 위해 고객조회 선행 후 진행
      lv_Vm.fn_isExistCustClick('01')
    }
  },

  /** 설명 : Step-4 마운트 된 후 호출 */
  mounted () {
    this.$bizUtil.insSrnLog('MSPCM012M')
    
    let lv_Vm = this

    // ************ 키패드 이벤트 등록 ************ //
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_SHOW, () => {
      // 키패드 노출시 처리
      lv_Vm.ShowBottomBtn = false
    })
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_HIDE, () => {
      // 키패드 숨김시 처리
      lv_Vm.ShowBottomBtn = true
    })
  },

  /** 설명 : Step-7 인스턴스가 Remove 되기 전 호출 */
  beforeDestroy () {
    // 키패드 이벤트 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_SHOW);
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_HIDE);
  },

  /************************************************************************************************
  * 사용자 함수 정의 영역
  ************************************************************************************************/
  methods: {
    /******************************************************************************
    * Function명 : fn_InitializeValue
    * 설명       : 화면 매핑 custCardVO 객체를 초기화 함
    ******************************************************************************/
    fn_InitializeValue () {
      this.disabledObj.nextBtnDisabled = false // 다음 버튼 활성화

      // 세대 추가 객체 초기화
      this.custCardVO.custApvrList = null
      this.custCardRegMdfcVO = null
      this.mnCustCardRegMdfcVO = null
      this.existHoshInfoListInqrVO = null
      this.newHoshInfoListInqrVO = null
      this.custCardVO.custApvrList = []
      this.custCardRegMdfcVO = {}
      this.mnCustCardRegMdfcVO = {}
      this.existHoshInfoListInqrVO = []
      this.newHoshInfoListInqrVO = []

      this.custNmResult = true // 이름 유효성 체크 결과
      this.custNmErrorMsg = '' // 이름 유효성 결과 에러 메시지
      this.chkKnbResult = true
      this.knbErrorMsg = ''
      this.chkCelnoResult = true
      this.celnoErrorMsg = ''
      this.intrResult = true

      this.fn_BinidInitValue() // 초기 값 세팅 함수 호출
    },
    /******************************************************************************
    * Function명 : fn_BinidInitValue
    * 설명       : 초기 값 세팅
    ******************************************************************************/
    fn_BinidInitValue () {
      this.selcJobMain = this.jobMains[0].value //직업 초기값은 '총무사무원'
      //this.jobEtc = this.jobEtcs[this.jobEtcs.length-1].value // 직업 기타 셀렉트박스의 초기값은 배열끝 '그 외 직업'
      this.jobEtc = '' // 직업기타 공백
      this.isJobSearch = false //다른직업찾기 영역 활성화
      this.addrType = this.addrTypes[0].value // 주소 초기값은 '자택'
      this.entryPath = this.entryPaths[3].value // 취득경로 초기값은 '기타'
      this.chkRrn = false // 미확보 체크는 uncheck가 default
      this.chkKnbResult = true // 주민번호 유효성 체크 결과 값
      this.isKnbBknoModified = false //주민번호 수정여부

      // 특정 밀리세컨 이후 errorClear함수 호출 - 화면로딩이 완료된 후에 clear해야 함.
      if (this.$refs.form !== undefined) {
        let lv_Vm = this
        this.interval = window.setTimeout( () => {
          if (lv_Vm.$refs.form !== undefined) {
            lv_Vm.$refs.form.errorClear()
          }
        }, 0.0001)
      }

    },
    /******************************************************************************
    * Function명 : fn_CheckSelectedItem
    * 설명       : row 선택 시 호출. 선택된 row의 radio value를 변경
    ******************************************************************************/
    fn_CheckSelectedItem (item) {
      this.selcJobMain = item.value
      this.custCardVO.jobCd = item.value
      this.custCardVO.jobCdNm = item.text

      //직업 기타 초기화
      this.jobEtc = ''
      this.isJobSearch = false
    },
    /******************************************************************************
    * Function명 : fn_BtnS3OnClick
    * 설명       : 보험직업 검색 팝업 창 호출
    ******************************************************************************/
    fn_BtnS3OnClick () {
      let lv_Vm = this
      lv_Vm.popup013 = lv_Vm.$bottomModal.open(MSPCM013P, {
          properties: {
            pJobCdNm : lv_Vm.searchJobcdNm
          },
          listeners: {
            confirmPopup: (pData) => {
              lv_Vm.$bottomModal.close(lv_Vm.popup013)

              // 파라미터 세팅
              lv_Vm.fn_ConfirmMSPCM013P(pData)
            },
            cancelPopup: (pData) => {
              lv_Vm.$bottomModal.close(lv_Vm.popup013)
            }
          }
        })
    },
    /******************************************************************************
    * Function명 : fn_ConfirmMSPCM013P
    * 설명       : MSPCM013P(보험직업검색)의 '확인'을 선택한 후 호출되는 함수
    ******************************************************************************/
    fn_ConfirmMSPCM013P (param) {
      if ( param !== null ){
        this.custCardVO.jobCd = param.jobCd
        this.custCardVO.jobCdNm = param.jobCdNm
        this.useMSPCM013P = true // 직업팝업에서 검색한 직업코드임

        // 직업 버튼 처리
        if (this.custCardVO.jobCd.trim() !== '') {
          // 직업 메인 라디오 버튼 처리
          let tmpJobMain = this.jobMains.filter(
            (val) => {
              if (val.value === this.custCardVO.jobCd) {
                return val
              }
            }
          )
          if (tmpJobMain.length > 0) { // 매핑되는 값이 있는 경우
            this.selcJobMain = tmpJobMain[0].value
            this.useMSPCM013P = false
          }

          // 직업 기타 셀렉트 박스 처리
          let tmpJobEtc = this.jobEtcs.filter(
            (val) => {
              if (val.value === this.custCardVO.jobCd) {
                return val
              }
            }
          )

          if (tmpJobEtc.length > 0) {
            this.jobEtc = tmpJobEtc[0].value
            this.useMSPCM013P = false
          } else {
            this.jobEtc = this.jobEtcs[this.jobEtcs.length-1].value // '셀렉트 박스 내 없으면 "그 외" 선택'
            this.isJobSearch = true //다른직업찾기 영역 활성화
          }
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_ClickEntryPath
    * 설명       : 취득경로 버튼 클릭 시 호출
    *              버튼 클릭 시 기존 값 제거 및 신규 값 custCardVO 세팅
    ******************************************************************************/
    fn_ClickEntryPath (data) {
      this.intrResult = true // 소개자 처리 변수 true로 초기화
      // 기존인 경우 변경 여부 체크
      this.custCardVO.coalCustYn = 'N'
      this.custCardVO.acutnCustYn = 'N'
      this.custCardVO.aptCustYn = 'N'
      this.custCardVO.rspnDstrtCustYn = 'N'

      if (data !== 'intdr') { // 소개가 아닌 경우
        this.custCardVO[data] = 'Y'
      } else {
        if (this.custCardVO.introYmd.trim() === '' || this.custCardVO.introYmd === '00000000' || this.custCardVO.introYmd === '0000-00-00') { // 소개일의 기본값이 없는 경우
          this.custCardVO.introYmd = this.$commonUtil.dateDashFormat(this.today) // 오늘 날짜로 세팅
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_SelIntdr
    * 설명       : 소개자 조회 I/F 호출 후 없는 경우 소개자 검색 팝업 창 호출
    ******************************************************************************/
    fn_SelIntdr (typeValue) {
      if (this.$bizUtil.isEmpty(this.custCardVO.custNm01.trim())) {
        let lv_Msg = this.$t('cm')['ECMC016'].replace('{0}', '소개자') // {0}은(는) 필수 입력값입니다.
        this.getStore('confirm').dispatch('ADD', lv_Msg)
      } else {
        // 소개자 조회 호출 - 고객명으로 조회
        this.isLoading = true
        let lv_Vm = this

        let pParams =
                  {
                    custNm: lv_Vm.custCardVO.custNm01.trim() // 고객명
                  }
        let trnstId = 'txTSSCM10S1'

        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null) {
              if (response.body.cMNewCustRegListVO !== null && response.body.cMNewCustRegListVO.length > 0) {
                
                if (response.body.cMNewCustRegListVO.length === 1) {
                  //1건만 있을 경우
                  let lv_CustVO = response.body.cMNewCustRegListVO[0]
                  lv_Vm.custCardVO.intdrChnlCustId = lv_CustVO.chnlCustId // 채널 고객ID
                  lv_Vm.custCardVO.custNm01Prev = lv_Vm.custCardVO.custNm01.trim() // 확정된 소개자 명 원본이름을 보관
                  lv_Vm.intrResult = true
                  lv_Vm.$addSnackbar("소개자 입력이 완료되었습니다.")
                } else {
                  //2건 이상 있을 경우
                  if (lv_Vm.entryPath === 'intdr') {
                    // 취득경로가 소개로 선택되어 있는 경우에만 호출하도록 처리. (예: 소개=>지인 선택 시, 포커스 아웃될때 호출되는 경우 있음.)
                    // 2건 이상 조회 시, 고객 검색 팝업창 호출
                    // 고객 조회 팝업창 띄우기
                    lv_Vm.popup129 = lv_Vm.$bottomModal.open(MSPCM129P, {
                      properties: {
                        pCustNm: lv_Vm.custCardVO.custNm01 // 고객명 파라미터
                      },
                      listeners: {
                        onPopupConfirm: (pData) => {
                          lv_Vm.$bottomModal.close(lv_Vm.popup129)

                          // 파라미터 세팅
                          lv_Vm.fn_ConfirmMSPCM129P(pData)
                        },
                        onPopupClose: () => {
                          lv_Vm.$bottomModal.close(lv_Vm.popup129)
                        }
                      }
                    },
                    {
                      properties: {
                        noHeader: true
                      }
                    })

                  } else {
                    // 취득경로가 다른 것으로 선택되어져 있는 경우 초기화 함.
                    lv_Vm.$addSnackbar("취득경로가 변경되어 소개자를 초기화 합니다.")
                    lv_Vm.custCardVO.custNm01 = '' // 고객명
                    lv_Vm.custCardVO.intdrChnlCustId = '' // 채널 고객ID
                    lv_Vm.custCardVO.custNm01Prev = '' //고객명 backup
                  }
                }
              } else {
                if (lv_Vm.entryPath === 'intdr') {
                  // 취득경로가 소개로 선택되어 있는 경우에만 알림창 띄우도록 처리. (예: 소개=>지인 선택 시, 포커스 아웃될때 호출되는 경우 있음.)
                  lv_Vm.$addSnackbar("입력된 소개자는 등록되어 있지 않습니다.")
                }
                lv_Vm.custCardVO.custNm01 = '' // 고객명
                lv_Vm.custCardVO.intdrChnlCustId = '' // 채널 고객ID
                lv_Vm.custCardVO.custNm01Prev = '' //고객명 backup
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

            lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      }
    },
    /*********************************************************
     * Function명: fn_IsNextBtnVisible
     * 설명: 다음버튼 보이게 하는 여부 처리 (focus일 경우 false, blur(focus out)일 경우 true)
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_IsNextBtnVisible (val, refNm) {
      if (this.$commonUtil.isMobile()) { // 모바일일 경우만 처리 - text input focus로 안드로이드 키보드 올라올 경우 '다음' 버튼 안보이게 처리
        this.isNextBtnVisible = val
        this.focusRefName = refNm
      }
    },
    /******************************************************************************
    * Function명 : fn_BlurIntdr
    * 설명       : 포커스 out 시 호출 - 소개자 명이 공백인 경우 고객명, 채널고객ID 모두 초기화
    ******************************************************************************/
    fn_BlurIntdr () {
      if (this.custCardVO.custNm01.trim() === '') { // 소개자명을 지울 경우 초기화 함
        this.custCardVO.custNm01 = '' // 고객명
        this.custCardVO.intdrChnlCustId = '' // 채널 고객ID
        this.custCardVO.custNm01Prev = ''
        this.intrResult = false // 소개자 조회
      } else {
        if (this.custCardVO.custNm01.trim() !== this.custCardVO.custNm01Prev) { // 소개자 명이 다를 경우 소개자 조회 I/F 호출
          this.intrResult = false // 소개자 조회
          // this.fn_SelIntdr('blurIntdr')
        } else {
          this.intrResult = true // 소개자 조회
        }
      }

      if (this.$commonUtil.isMobile) { // 모바일일 경우만 다음버튼 활성화 되게 처리
        this.fn_IsNextBtnVisible(true)
      }
    },
    /*********************************************************
     * Function명: fn_BackBtnClicked
     * 설명: 스마트폰 back 버튼 클릭 시 호출
     * Params: N/A
     * Return: N/A
     *********************************************************/
    fn_BackBtnClicked () { // 스마트폰의 back 버튼 선택 시 처리

      // Global 컴펌이 show 상태인지 확인
      // let confirmMsg = this.getStore('confirm')
      // if (confirmMsg.getters.getState.isShow) {
      //   confirmMsg.dispatch('REMOVE') // show 상태이면 닫기
      // } else if (this.isTSSSP130P) { // 주소검색창
      //   this.isTSSSP130P = false
      // } else if (this.isConfirmMove) {
      //   this.isConfirmMove = false
      // } else if (this.$popupManager.getPopupList().length > 0) { // popupList가 1개 이상인 경우
      //   this.$popupManager.removeLastPopup() // 가장 최근 팝업창 close
      // }

      if(isBackBtn){
        this.isBackBtn = false
        this.fn_CloseBackCnfrmPop('Close')
      }else{
        this.isBackBtn = true
        this.fn_CloseBackCnfrmPop('Open')
      }

    },
    /*********************************************************
     * Function명: fn_BackBtnClickedConfirm
     * 설명: 스마트폰 back 버튼 클릭 시, 다른화면 이동 전 알림창의 '나가기' 버튼 클릭 시 호출
     * Params: positive or negative
     * Return: N/A
     *********************************************************/
    fn_BackBtnClickedConfirm (param) {
      let lv_Obj = this.$commonUtil.getLastScreen() // history 배열의 마지막 객체를 가져옴

      //고객동의 첫화면?
      

      // if (lv_Obj !== undefined && lv_Obj !== null) {
      //   if (lv_Obj.name === 'TSSSP107M') { // 등록 완료 화면에서 온 경우 '고객홈'으로 이동
      //     this.$router.push({name: 'TSSSP002M'})
      //   } else if (lv_Obj.name === 'TSSSP115M') {
      //     this.$router.go(-1)
      //   } else if (lv_Obj.name === 'TSSSP101M') {
      //     if (this.backCount > 0) {
      //       let movCnt = (this.backCount * -2) - 1
      //       this.$router.go(movCnt)
      //     } else {
      //       this.$router.go(-1)
      //     }
      //   } else {
      //     this.$router.push(lv_Obj)
      //   }
      // } else {
      //   if (this.backCount > 0) {
      //     let movCnt = (this.backCount * -2) - 1
      //     this.$router.go(movCnt)
      //   } else {
      //     this.$router.go(-1)
      //   }
      // }
    },
    /******************************************************************************
    * Function명 : fn_SaveBfCnfrmPop
    * 설명       : 저장 전 동의 안내
    ******************************************************************************/
    fn_SaveBfCnfrmPop (param) {

      switch (param) {
        case 'Open':
          this.$refs.bottomSheetSaveBfCnfrm.open()
          break
        case 'Cancel':
          this.$refs.bottomSheetSaveBfCnfrm.close()
          break
        case 'Save':
          this.$refs.bottomSheetSaveBfCnfrm.close()
          this.fn_ConfirmSaveBfCnfrmPop()
          break      
      }
      
    },
    /******************************************************************************
    * Function명 : fn_CloseBackCnfrmPop
    * 설명       : 종료동의 안내 
    *           - OPEN(컨펌팝업열기), Cancel(화면유지), Close(동의종료) 선택에 대한 화면 이동
    ******************************************************************************/
    fn_CloseBackCnfrmPop (param) {

      switch (param) {
        case 'Open':
          this.$refs.bottomSheetBackCnfrm.open()
          break
        case 'Cancel':
          this.$refs.bottomSheetBackCnfrm.close()
          break
        case 'Close':
          //Close : 프로세스 종료, 첫화면이동
          this.$refs.bottomSheetBackCnfrm.close()
          
          let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
          let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M

          window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화

          if (_.isEmpty(preSrnObj.rtnParam)) {
            let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo
            // 필동 정보 초기화
            storeCustInfo.infoPrcusCnsntTypCd = ''
            storeCustInfo.agreeTypeMcnst = false
            // 마동 정보 초기화
            storeCustInfo.mktChkList = ''
            storeCustInfo.segmentType1 = ''
            storeCustInfo.mktCnsntAvlPrd = ''

            window.vue.getStore('cmStore').dispatch('ADD', storeCustInfo)
            this.$router.go(-(pageCnt+1))
          } else {
            this.$router.push({ name: preSrnObj.srnId, params: preSrnObj.rtnParam })
          }
          break
      }
      
    },
    /******************************************************************************
    * Function명 : fn_CntryInfoPop
    * 설명       : 국가정보 입력 권유 컨펌 
    *              -Open(팝업 열기), Add(외국인 등록증 진위확인 화면 이동), Close(창닫기)
    ******************************************************************************/
    fn_CntryInfoPop (param) {

      switch (param) {
        case 'Open':
          this.$refs.bottomSheetCntryInfo.open()
          break
        case 'Add':
          this.$refs.bottomSheetCntryInfo.close()
          this.fn_BtnCntryCodeClick()
          break
        case 'Close':
          this.$refs.bottomSheetCntryInfo.close()
          break
      }
      
    },
    /******************************************************************************
    * Function명 : fn_NiceAuthPop
    * 설명       : 나이스 인증 실패 결과 안내 
    *           - OPEN(컨펌팝업열기), Confirm(창닫기) 선택에 대한 화면 이동
    ******************************************************************************/
    fn_NiceAuthPop (param) {

      switch (param) {
        case 'Open':
          this.$refs.niceAuthPop.open()
          break
        case 'Confirm':
          this.$refs.niceAuthPop.close()
          break
      }
      
    },
    /******************************************************************************
    * Function명 : fn_ClosedConfirmOpen 
    * 설명       : 백버튼 예시(테스트용)
    ******************************************************************************/
    fn_ClosedConfirmOpen () {
      this.isBackBtn = true
      this.$refs.bottomSheetBackCnfrm.open()
    },
    /******************************************************************************
    * Function명 : fn_ConfirmMSPCM129P
    * 설명       : 소개자 조회 검색 팝업창에서 '확인' 버튼 클릭 시 호출
    ******************************************************************************/
    fn_ConfirmMSPCM129P (param) {
      if (param !== null && param !== 'removeLastPopup') { // 확인 버튼 선택 시, 파라미터가 넘어옴.
        this.custCardVO.custNm01 = param.custNm // 고객명
        this.custCardVO.intdrChnlCustId = param.chnlCustId // 채널 고객ID
        this.custCardVO.custNm01Prev = this.custCardVO.custNm01 // 확정된 소개자 명 원본이름을 보관
        this.intrResult = true // 소개자 정상 처리
        // blur와 클릭 이벤트가 이중 발생하여 구분하여 처리
        this.$addSnackbar("소개자 입력이 완료되었습니다.")
      } else { // 취소 버튼 선택 시, 값이 없음.
        // 기존에 입력되어 있는 소개자가 없는 경우에만 소개자 항목 초기화 함.
        if (this.custCardVO.intdrChnlCustId === null || this.custCardVO.intdrChnlCustId === '') {
          this.custCardVO.custNm01 = '' // 고객명
          this.custCardVO.intdrChnlCustId = '' // 채널 고객ID
          this.custCardVO.custNm01Prev = ''
          this.intrResult = false
          this.$addSnackbar("소개자 입력이 되지 않았습니다.")
        }
      }
    },
    /*********************************************************
     * Function명: fn_isExistCustClick
     * 설명: 기존고객 확인 조회
     * Params: flag
     * Return: N/A
     *********************************************************/
    fn_isExistCustClick (flag) {
      //주민번호 유효성 체크
      this.fn_CheckSsnValidation()

      this.fn_InitializeValue() //vo 초기화 셋팅
      //this.existingCustomer = false //검색 searchRsltMsg 보이기 여부
      this.searchRsltMsg = '' //기존고객, 신규고객 메시지
      this.custTypeNm = '' //신규, 기존 문구
      this.isLoading = true
      let lv_Vm = this
      let pParams = {}

      if (lv_Vm.infoPrcusCnsntTypCd === '19') { // 마동만 선택 시, 성별코드+000000 처리된 주민번호로 셋팅
        lv_Vm.custCardVO.knbBkno = lv_Vm.custCardVO.knbBknoBackup
        lv_Vm.custCardVO.knbBknoCipher = ''
      }

      // 핸드폰번호 '-' 추가
      let lv_Celno = lv_Vm.custCardVO.celno.trim()
      lv_Vm.custCardVO.celno = lv_Vm.fn_FullPhoneDashFormat(lv_Celno, lv_Vm.custCardVO.celnoFrno, lv_Vm.custCardVO.celnoBkno)

      let lv_UserInfo = lv_Vm.getStore('userInfo').getters.getUserInfo // 사용자 정보

      if (lv_Vm.custCardVO.isExistCustYN === 'Y') { //기존고객
        pParams =
                    {
                      prcsfCd: flag, // 등록 플래그 값(필수값)
                      custNm: lv_Vm.custCardVO.custNm, // 고객명
                      knbFrno: '',
                      knbBknoCipher: '',
                      knbBkno: '',
                      cnsltNo: lv_UserInfo.userId, // 컨설턴트 번호
                      celno: lv_Vm.custCardVO.celno, // 핸드폰번호 ('-'하이픈포함)
                      infoPrcusCnsntTypCd: lv_Vm.infoPrcusCnsntTypCd, // 정보활용동의코드
                      chnlCustId: lv_Vm.custCardVO.chnlCustId // 기존고객- 채널고객ID (Agent파일ID)
                    }
      } 
      else {//신규고객
        pParams =
                    {
                      prcsfCd: flag, // 등록 플래그 값(필수값)
                      custNm: lv_Vm.custCardVO.custNm, // 고객명
                      knbFrno: lv_Vm.custCardVO.knbFrno,
                      knbBknoCipher: lv_Vm.custCardVO.knbBknoCipher,
                      // knbBknoCipher: '', // GA테스트용 삭제처리
                      knbBkno: lv_Vm.custCardVO.knbBkno,
                      cnsltNo: lv_UserInfo.userId, // 컨설턴트 번호
                      celno: lv_Vm.custCardVO.celno, // 핸드폰번호 ('-'하이픈포함)
                      infoPrcusCnsntTypCd: lv_Vm.infoPrcusCnsntTypCd, // 정보활용동의코드
                      chnlCustId: '' // 신규고객- 채널고객ID (Agent파일ID) 공백으로
                    }
      }

      // GA 매니저 대행 컨설턴트 변경시
      if(this.$cmUtil.fn_GachangeTF()){
        pParams.cnsltNo = this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
      //매니저가 컨설턴트를 변경했을경우
      this.stndKeyList = []
      if(this.$cmUtil.fn_GachangeTF()){
        this.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: this.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
      }

      // 2023.05.30 txTSSCM13S1에서 변경(주민번호 평문에서 암호화로 조회)
      let trnstId = 'txTSSCM13S2'

      lv_Vm.post(lv_Vm, pParams, trnstId, 'S')
        .then(function (response) {
          if (response.body !== null) {
            if (!lv_Vm.$bizUtil.isEmpty(response.body.iCHoshInfoListInqrVO) &&
                !lv_Vm.$bizUtil.isEmpty(response.body.iCHoshInfoListInqrVO[0]) &&
              response.body.iCHoshInfoListInqrVO[0].chnlCustId !== undefined && 
            response.body.iCHoshInfoListInqrVO[0].chnlCustId !== null && 
            response.body.iCHoshInfoListInqrVO[0].chnlCustId.trim() !== '') { // 기존고객

              lv_Vm.$data.custCardRegMdfcVO = response.body
              lv_Vm.isLoading = false
              //lv_Vm.existingCustomer = true
              lv_Vm.searchRsltMsg = '기존고객'
              lv_Vm.custTypeNm = '기존'
              lv_Vm.fn_BindCustCardVO()

              // 필컨&기존 고객이지만 주민등록번호 확보가 안될 경우 '중복조회' 팝업
              if (lv_Vm.infoPrcusCnsntTypCd !== '19' && lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].chnlCustScCd === '02') {
                lv_Vm.custCardVO.isExistCustYN = 'N' // 기존고객이지만 주민번호가 없을 경우 : isExistCustYN = 'N' 변경
                lv_Vm.fn_OpenMSPCM021P()
              }

              if (lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].foreignPartnerNo !== undefined && lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].foreignPartnerNo.trim() !== '') {
                lv_Vm.custCardVO.foreignPartnerNo = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].foreignPartnerNo.trim()
                lv_Vm.custCardVO.bpCustEngNm = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].bpCustEngNm.trim()
                lv_Vm.custCardVO.bpPsprtNo = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].bpPsprtNo.trim()
                lv_Vm.custCardVO.bpNatyCd = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].bpNatyCd.trim()
                lv_Vm.custCardVO.bpNatyNm = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].bpNatyNm.trim()
                lv_Vm.custCardVO.bpDmclScCd = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].bpDmclScCd.trim()
                lv_Vm.custCardVO.bpDmclScCdNm = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].bpDmclScCdNm.trim()
              }

              let forigInfo = {bpPsprtNo: lv_Vm.custCardVO.bpPsprtNo, bpNatyNm: lv_Vm.custCardVO.bpNatyNm}
              lv_Vm.lv_CustForigInfo = [forigInfo]

              // 기존회원이 외국인이고, 국가정보 없으면 입력 권유
              if (lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].knbScCd === 'ZPER02' && lv_Vm.$bizUtil.isEmpty(lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].natyCd)) {
                lv_Vm.fn_CntryInfoPop('Open')
              }
            } else { // 신규고객
              //lv_Vm.existingCustomer = true
              lv_Vm.searchRsltMsg = '신규고객'
              lv_Vm.custTypeNm = '신규'

              // 신규고객 필컨선택 후 '중복조회' 팝업창 호출
              if ( lv_Vm.infoPrcusCnsntTypCd !== '19' ) {
                lv_Vm.fn_OpenMSPCM021P()
              }
            }
            lv_Vm.$addSnackbar(lv_Vm.custCardVO.custNm + " 님은 '" + lv_Vm.searchRsltMsg + "'으로 확인 되었습니다.")
          } else { // 조회 결과가 없는 경우
            //lv_Vm.existingCustomer = false
            lv_Vm.searchRsltMsg = ''
            lv_Vm.custTypeNm = ''

            if (response.msgComm.msgCd === 'EEAC928') {
              lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.$t('co')['ECOC009']) // '조회하고자 하는 데이터가 없습니다'
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }
          }
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_BindCustCardVO
    * 설명       : txTSSCM13S1 호출 후, 고객 카드 조회 서비스 결과를
                   화면 VO(custCardVO)에 bind
    ******************************************************************************/
    fn_BindCustCardVO () {
      // 주고객 VO 바인딩 = txTSSCM13S1 호출 시,
      // this.custCardRegMdfcVO.iCHoshInfoListInqrVO[0] 번째 값에 무조건 주고객(mnCustRltnCd : 본인(01) or 배우자(02)) 조회되어 옴 ([1],[2],[3].. 번째 : 세대원)
      // custCardVO 에 없는 값들 바인딩 (고객명, 주민번호, 휴대폰번호는 기존 입력값 사용)
      // this.mnCustCardRegMdfcVO = this.custCardRegMdfcVO.iCHoshInfoListInqrVO[0] // 주고객 데이터 셋팅
      this.mnCustCardRegMdfcVO = JSON.parse(JSON.stringify(this.custCardRegMdfcVO.iCHoshInfoListInqrVO[0])) // 주고객 데이터 객체 복사

      for (let key in this.mnCustCardRegMdfcVO) {
        if (this.custCardVO[key] === null || this.custCardVO[key] === undefined || this.custCardVO[key] === '') {
          this.custCardVO[key] = this.mnCustCardRegMdfcVO[key]
        }
      }
      /************************************************************************************
       * ----------- 직업 세팅 start -----------
       ************************************************************************************/
      // 직업 segment 세팅은 fn_BindSegmentBox() 함수에서 처리
      this.custCardVO.jobCd = this.mnCustCardRegMdfcVO.custJobCd
      this.custCardVO.jobCdNm = this.mnCustCardRegMdfcVO.custJobCdNm
      /************************************************************************************
       * ----------- 직업 세팅 end -----------
       ************************************************************************************/

      /************************************************************************************
       * ----------- 주소 세팅 start -----------
       ************************************************************************************/
      // 주소 segment 세팅은 fn_BindSegmentBox() 함수에서 처리

      // 자택 / 직장 주소와 세대원 핸드폰번호는 X플래그로 무조건 변경처리함.
      // 기존 고객카드 내용(조회된내용)을 우선 조회 후 수정 업데이트 * 공백으로 업데이트는 불가능
      // 조회된 주소로 바인딩
      this.custCardVO.homFrnoPstcd = this.mnCustCardRegMdfcVO.homPstcd
      this.custCardVO.homAddr = this.mnCustCardRegMdfcVO.homAddr
      this.custCardVO.bldgNo01 = this.mnCustCardRegMdfcVO.bldgNo01
      this.custCardVO.homDtlad = this.mnCustCardRegMdfcVO.homDtlad
      this.custCardVO.adtnAddr01 = this.mnCustCardRegMdfcVO.adtnAddr01
      this.custCardVO.homAddrScCd = this.mnCustCardRegMdfcVO.homAddrScCd
      this.custCardVO.homAddrRefnCd = this.mnCustCardRegMdfcVO.homAddrRefnCd
      this.custCardVO.homAddrStatCd = this.mnCustCardRegMdfcVO.homAddrStatCd
      this.custCardVO.jobpFrnoPstcd = this.mnCustCardRegMdfcVO.jobpPstcdSno
      this.custCardVO.jobpAddr = this.mnCustCardRegMdfcVO.jobpAddr
      this.custCardVO.bldgNo02 = this.mnCustCardRegMdfcVO.bldgNo02
      this.custCardVO.jobpDtlad = this.mnCustCardRegMdfcVO.jobpDtlad
      this.custCardVO.adtnAddr02 = this.mnCustCardRegMdfcVO.adtnAddr02
      this.custCardVO.jobpAddrScCd = this.mnCustCardRegMdfcVO.jobpAddrScCd
      this.custCardVO.jobpAddrRefnCd = this.mnCustCardRegMdfcVO.jobpAddrRefnCd
      this.custCardVO.jobpAddrStatCd = this.mnCustCardRegMdfcVO.jobpAddrStatCd
      /************************************************************************************
       * ------- 주소 세팅 end -----------
       ************************************************************************************/

      /************************************************************************************
       * ----------- 국적정보 세팅 start -----------
      ************************************************************************************/
      this.custCardVO.custEngNm = this.mnCustCardRegMdfcVO.custEngNm
      this.custCardVO.natyCd = this.mnCustCardRegMdfcVO.natyCd
      this.custCardVO.natyNm = this.mnCustCardRegMdfcVO.natyNm
      this.custCardVO.dmclScCd = this.mnCustCardRegMdfcVO.dmclScCd
      this.custCardVO.dmclScCdNm = this.mnCustCardRegMdfcVO.dmclScCdNm
      this.custCardVO.psprtNo = this.mnCustCardRegMdfcVO.psprtNo
      this.custCardVO.foreignPartnerNo = this.mnCustCardRegMdfcVO.foreignPartnerNo
      this.custCardVO.bpCustEngNm = this.mnCustCardRegMdfcVO.bpCustEngNm
      this.custCardVO.bpPsprtNo = this.mnCustCardRegMdfcVO.bpPsprtNo
      this.custCardVO.bpNatyCd = this.mnCustCardRegMdfcVO.bpNatyCd
      this.custCardVO.bpNatyNm = this.mnCustCardRegMdfcVO.bpNatyNm
      this.custCardVO.bpCustEngNm = this.mnCustCardRegMdfcVO.bpCustEngNm
      this.custCardVO.bpDmclScCd = this.mnCustCardRegMdfcVO.bpDmclScCd
      this.custCardVO.bpDmclScCdNm = this.mnCustCardRegMdfcVO.bpDmclScCdNm

      /************************************************************************************
       * ------- 국적정보 세팅 end -----------
       ************************************************************************************/

      /************************************************************************************
       * ------- 취득경로 세팅 start -----------
       ************************************************************************************/
      // 소개일자 조회된 값으로 세팅
      if (this.mnCustCardRegMdfcVO.introYmd.trim() !== '') { // 소개일이 날짜가 아닌 값이 들어온 경우 ''처리함.
        let lv_IntroYmd = this.mnCustCardRegMdfcVO.introYmd.trim().replace(/-/g, '')
        if (lv_IntroYmd === '00000000' || lv_IntroYmd === 'Invalid date' || Number.isNaN(Number(lv_IntroYmd))) {
          this.custCardVO.introYmd = ''
        } else {
          this.custCardVO.introYmd = this.$commonUtil.dateDashFormat(lv_IntroYmd) // 소개일
          this.custCardVO.custNm01Prev = this.custCardVO.custNm01.trim() // 확정된 소개자 명 원본이름을 보관
          this.custCardVO.introYmdPrev = this.custCardVO.introYmd // 소개일 원본 보관
        }
      }

      // 취득경로 조회된 값으로 세팅
      this.custCardVO.acutnCustYn = this.mnCustCardRegMdfcVO.acutnCustYn // 지인
      this.custCardVO.aptCustYn = this.mnCustCardRegMdfcVO.aptCustYn // 개척
      this.custCardVO.rspnDstrtCustYn = this.mnCustCardRegMdfcVO.rspnDstrtCustYn // 기타
      this.custCardVO.coalCustYn = this.mnCustCardRegMdfcVO.coalCustYn // 제휴 - 사랑온에서는 선택 불가
      // 취득경로 segment 세팅은 fn_BindSegmentBox() 함수에서 처리

      /************************************************************************************
       * ----------- 취득경로 세팅 end -----------
       ************************************************************************************/

      // segment-box 세팅 (고객명, 직업, 주소, 취득경로)
      this.fn_BindSegmentBox()

      // 기존 고객 수정 불가 항목 disable 처리
      // this.fn_SetDisabledComponent()

      // 외국인 여부
      if (this.custCardVO.knbScCd === 'ZPER02') { // 외국인
        this.isForeigner = true
      } else {
        this.isForeigner = false
      }

      // 세대원 리스트 매핑 - 자녀만 카드리스트에 포함됨
      if (this.custCardRegMdfcVO.iCHoshInfoListInqrVO !== null && this.custCardRegMdfcVO.iCHoshInfoListInqrVO.length > 1) {
        this.existHoshInfoListInqrVO = null
        this.existHoshInfoListInqrVO = []

        for (let i = 1; i < this.custCardRegMdfcVO.iCHoshInfoListInqrVO.length; i++) {
          if (this.custCardRegMdfcVO.iCHoshInfoListInqrVO[i].mnCustRltnCd === '03' || this.custCardRegMdfcVO.iCHoshInfoListInqrVO[i].mnCustRltnCd === '04' || this.custCardRegMdfcVO.iCHoshInfoListInqrVO[i].mnCustRltnCd === '08') {
            this.existHoshInfoListInqrVO.push(this.custCardRegMdfcVO.iCHoshInfoListInqrVO[i])
          }
        }

        let lv_Vm = this
        lv_Vm.existHoshInfoListInqrVO.forEach(
          (item) => { // 필수컨설팅, 마케팅 완료일, iCCustInfoPrcusCnsntVO 초기화
            item.customerType = 'exist'
            //item.existingCustomer = true // 기존/ 신규고객 구분코드
            item.prcsfCd = 'U'
            item.mcnstYmd = ''
            item.mktYmd = ''
            item.iCCustInfoPrcusCnsntVO = {}
            item.selcAgreeTypeMcnst = this.selcAgreeTypeMcnst // 필수컨설팅 체크
            item.selcAgreeTypeMkt = this.selcAgreeTypeMkt // 마케팅동의 체크
            item.infoPrcusCnsntTypCd = this.infoPrcusCnsntTypCd // 정보활용동의코드
            item.isPossibleFetus = false
            //item.isKnbChanged = false
            item.isCustNmChanged = false
            item.isCelnoChanged = false
            item.isCustJobCdChanged = false
            item.isNew = false
            item.knbFrno = item.knb.substring(0, 6)
            item.knbBkFstNo = item.knb.substr(6, 1)
            item.ralDob = item.knbFrno ? this.$bizUtil.getDotDateToFullDate(item.knbFrno) : ''
            item.isMobile = 'Y' // 모바일 사용여부
            item.rlnmCnfRsltCd = (this.$bizUtil.isEmpty(item.rlnmCnfRsltCd)) ? '' : item.rlnmCnfRsltCd
            item.knbBackup = item.knb
          }
        )
      }
      // 화면에 보여질 세대원 셋팅
      this.iCHoshInfoList = this.existHoshInfoListInqrVO
    },
    /******************************************************************************
    * Function명 : fn_BindSegmentBox
    * 설명       : Sement-Box(고객명, 직업, 주소, 취득경로) 세팅
    ******************************************************************************/
    fn_BindSegmentBox () {
      // 직업 버튼 처리
      if (this.custCardVO.jobCd.trim() !== '') {
        this.useMSPCM013P = true
        // 직업 메인 버튼 처리
        let tmpJobMain = this.jobMains.filter(
          (val) => {
            if (val.value === this.custCardVO.jobCd) {
              return val
            }
          }
        )
        if (tmpJobMain.length > 0) { // 매핑되는 값이 있는 경우
          this.selcJobMain = tmpJobMain[0].value
          this.useMSPCM013P = false
        } else {

          // 직업 기타 셀렉트 박스 처리
          let tmpJobEtc = this.jobEtcs.filter(
            (val) => {
              if (val.value === this.custCardVO.jobCd) {
                return val
              }
            }
          )

          if (tmpJobEtc.length > 0) {
            this.jobEtc = tmpJobEtc[0].value
            this.useMSPCM013P = false
          } else {
            //jobCd가 있으나 매칭이 안되는 경우, 셀렉트박스 '그 외 직업' 선택, 다른직업찾기 input 에 jobCdNm 넣기
            this.selcJobMain = ''
            this.jobEtc = this.jobEtcs[this.jobEtcs.length-1].value
            this.isJobSearch = true //다른직업찾기 영역 활성화
          }
        }
      } else {
        // 직업코드가 없으면 초기화 함.
        if (this.custCardVO.jobCd.trim() === '') {
          this.selcJobMain = ''
          this.jobEtc = this.jobEtcs[this.jobEtcs.length-1].value
          this.isJobSearch = true //다른직업찾기 영역 활성화
        }
      }

      // 주소 세팅
      // 자택, 직장 데이터가 동시에 들어올 경우 자택 주소가 우선 순위임
      if (this.custCardVO.homAddr.trim() !== '') { // 집주소가 먼저 있는지 체크
        this.addrType = this.addrTypes[0].value
      } else if (this.custCardVO.jobpAddr.trim() !== '') { // 직장 주소 체크
        this.addrType = this.addrTypes[1].value
      }

      // 취득경로 세팅
      if (this.custCardVO.coalCustYn === 'Y') { // 제휴 고객인 경우에는 취득경로 선택불가하게 처리
        this.entryPath = ''
        this.disabledYN.entryDisabd = true // 취득경로를 disabled함.
      } else {
        if (this.custCardVO.acutnCustYn === 'Y') { // 지인
          this.entryPath = this.entryPaths[0].value
        } else if (this.custCardVO.aptCustYn === 'Y') { // 개척
          this.entryPath = this.entryPaths[2].value
        } else if (this.custCardVO.rspnDstrtCustYn === 'Y') { // 기타
          this.entryPath = this.entryPaths[3].value
        } else if (this.custCardVO.intdrChnlCustId.trim() !== '') { // 소개
          this.entryPath = this.entryPaths[1].value
          this.intrResult = true
        }
        
        this.disabledYN.entryDisabd = false // 취득경로를 disabled 해제 함.
        if (this.$bizUtil.isEmpty(this.entryPath)) this.entryPath = this.entryPaths[3].value
      }

      //직업&취득경로 값이 있으면 '완료' 버튼 활성화
      if( (this.selcJobMain.length > 0 || this.jobEtc.length > 0) && this.entryPath.length > 0 ){
        this.fn_IsNextBtnVisible(true)
      }else{
        this.fn_IsNextBtnVisible(false)
      }
    },
    /******************************************************************************
    * Function명 : fn_OpenMSPCM021P
    * 설명       : 고객 중복조회 팝업 열기
    ******************************************************************************/
    fn_OpenMSPCM021P () {
      let lv_Vm = this
      lv_Vm.popup021 = lv_Vm.$bottomModal.open(MSPCM021P, {

        properties: {
          pCustCardVO : lv_Vm.custCardVO,
          pInfoPrcusCnsntTypCd: lv_Vm.infoPrcusCnsntTypCd, // 정보활용동의코드
          pIsForeigner: lv_Vm.isForeigner //외국인 구분
        },
        listeners: {
          onCusDupSearchPopup: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.popup021)

            lv_Vm.custCardRegMdfcVO = lv_Vm.$bizUtil.cloneDeep(pData.custCardRegMdfcVO) //조회된 주고객, 세대원정보
            let lv_TmpCustCardVO =  lv_Vm.$bizUtil.cloneDeep(pData.custCardVO) //고객일반정보
            let lv_CustomerType = pData.customerType

            
            //기존고객 조회 될 수 있으므로 마케팅 동의 유무 따짐?
            if (lv_TmpCustCardVO.infoPrcusCnsntTypCd === '19') { // 마케팅동의만 선택 시 임시고객 (주민번호미확보)
              lv_Vm.custCardVO.knbFrno = lv_TmpCustCardVO.knbFrno
              lv_Vm.custCardVO.knbBkno = lv_TmpCustCardVO.knbBkFstNo + '000000' // 성별코드 + 000000 값
              lv_Vm.custCardVO.knbBknoCipher = '' // 뒷자리암호화필드 공백처리
            } else { // 필수컨설팅 동의 포함 선택 시 가망고객 (주민번호확보)
              lv_Vm.custCardVO.knbFrno = lv_TmpCustCardVO.knbFrno
              if (lv_Vm.$commonUtil.isMobile()) {
                lv_Vm.custCardVO.knbBknoCipher = lv_TmpCustCardVO.knbBknoCipher
              } else {
                lv_Vm.custCardVO.knbBkno = lv_TmpCustCardVO.knbBkno
              }
            }

            if (lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].foreignPartnerNo !== undefined && lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].foreignPartnerNo.trim() !== '') {
              lv_Vm.custCardVO.foreignPartnerNo = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].foreignPartnerNo.trim()
              lv_Vm.custCardVO.bpCustEngNm = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].bpCustEngNm.trim()
              lv_Vm.custCardVO.bpPsprtNo = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].bpPsprtNo.trim()
              lv_Vm.custCardVO.bpNatyCd = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].bpNatyCd.trim()
              lv_Vm.custCardVO.bpNatyNm = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].bpNatyNm.trim()
              lv_Vm.custCardVO.bpDmclScCd = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].bpDmclScCd.trim()
              lv_Vm.custCardVO.bpDmclScCdNm = lv_Vm.custCardRegMdfcVO.iCHoshInfoListInqrVO[0].bpDmclScCdNm.trim()
            }

            if(lv_CustomerType === 'exist' && lv_Vm.custCardRegMdfcVO !== null && typeof lv_Vm.custCardRegMdfcVO !== 'undefined' ){
              //기존 회원 일 경우, 설정 재셋팅
              lv_Vm.searchRsltMsg ="기존고객"
              lv_Vm.custTypeNm = '기존'
              lv_Vm.fn_BindCustCardVO()
              
              // 외국인이고 국가정보 없으면 입력 권유
              if (lv_Vm.custCardVO.knbScCd === 'ZPER02' && lv_Vm.$bizUtil.isEmpty(lv_Vm.custCardVO.natyCd )) {
                lv_Vm.fn_CntryInfoPop('Open')
              }
            }else{
              //신규고객 일 경우 UI초기셋팅
              lv_Vm.fn_BinidInitValue()

              // 신규&필컨 (중복조회는 신규&필컨)만 조회 하므로 외국인만 체크하여 국가정보 입력 권유
              if (lv_Vm.custCardVO.knbScCd === 'ZPER02') {
                lv_Vm.fn_CntryInfoPop('Open')
              }
            }

          },
          onCusDupSearchPopupClose: () => {
            // 메인화면으로 이동
            lv_Vm.$bottomModal.close(lv_Vm.popup021)

            let preSrnObj = window.vue.getStore('cmStore').getters.getState.preSrnObjCM050M
            let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M

            window.vue.getStore('cmStore').dispatch('PRE_SRN_OBJ_CM050M', {}) // 진입전 정보 초기화

            if (_.isEmpty(preSrnObj.rtnParam)) {
              let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo
              // 필동 정보 초기화
              storeCustInfo.infoPrcusCnsntTypCd = ''
              storeCustInfo.agreeTypeMcnst = false
              // 마동 정보 초기화
              storeCustInfo.mktChkList = ''
              storeCustInfo.segmentType1 = ''
              storeCustInfo.mktCnsntAvlPrd = ''

              window.vue.getStore('cmStore').dispatch('ADD', storeCustInfo)
              this.$router.go(-(pageCnt+1))
            } else {
              this.$router.push({ name: preSrnObj.srnId, params: preSrnObj.rtnParam })
            }
          }
        }
      })
    },
    /*********************************************************
     * Function명: fn_BtnCntryCodeClick
     * 설명: 국가코드/여권 버튼 클릭 시 호출
     * Params: positive
     * Return: N/A
     *********************************************************/
    fn_BtnCntryCodeClick () {
      if (this.custCardVO.foreignPartnerNo !== undefined && this.custCardVO.foreignPartnerNo === 'E999999999') {
        this.getStore('confirm').dispatch('ADD', this.$t('cm')['ECMC078'])
      } else {
        // this.fn_callAlienRegistrationPlugin() // Native Module 처리가 안되어 임시 주석처리 2022-08-03 
        this.fn_OpenMSPCM019P()
      }
    },
    /******************************************************************************
    * Function명 : fn_callAlienRegistrationPlugin
    * 설명       : 외국인등록증 스크래핑 플러그인 호출
    *******************************************************************************/
    fn_callAlienRegistrationPlugin () {
      let lv_Vm = this
      lv_Vm.fn_OpenMSPCM019P()
    },
    fn_OpenMSPCM019P () {
      let lv_Vm = this

      lv_Vm.popup019 = lv_Vm.$bottomModal.open(MSPCM019P, {
        properties: {
          tempCustCardVo: lv_Vm.custCardVO
        },
        listeners: {
          onPopupCancel: () => {
            lv_Vm.$bottomModal.close(lv_Vm.popup019)
          },
          onPopupClose: () => {
            lv_Vm.$bottomModal.close(lv_Vm.popup019)
            // 외국인고객 스크래핑 여부
            lv_Vm.custCardVO.scrapYn = 'Y'

            //MSPCM015P 호출
            setTimeout(function() {
              lv_Vm.fn_OpenMSPCM015P()
            }, '500')
          }
        }
      })
    },
    /*********************************************************
     * Function명: fn_CountryCodePopRtn
     * 설명: 국가코드/여권 팝업 확인버튼 클릭 시 호출
     * Params: positive
     * Return: N/A
     *********************************************************/
    fn_CountryCodePopRtn (value) {
      if (value !== undefined && value !== null) {
        Object.assign(this.custCardVO, value)

        value.psprtNo = value.psprtNo === 'IMSI' ? 'IMSI' + value.knbFrno : value.psprtNo

        let forigInfo = {
          bpPsprtNo: value.psprtNo,
          bpNatyNm: value.natyNm
        }

        this.lv_CustForigInfo = [forigInfo]
      }
    },
    /*********************************************************
     * Function명: fn_FullPhoneDashFormat
     * 설명: 핸드폰번호 Dash 처리
     * Params: 전화번호
     * Return: Dash된 전화번호
     *********************************************************/
    fn_FullPhoneDashFormat (param, celnoFrno, celnoBkno) {
      if(!param) return ''

      let lv_Celno = param // - 없는 전화번호
      let lv_CelnoFrno = celnoFrno // 휴대폰번호 앞자리 추출
      
      if (lv_CelnoFrno !== undefined && lv_CelnoFrno.length > 3) { // 휴대폰 앞 자리가 3자리가 아닌, 0130 혹은 0505과 같은 dash 별도 처리
        lv_Celno = lv_CelnoFrno + '-' + this.fn_PhoneDashFormat(celnoBkno)
      } else {
        lv_Celno = this.fn_PhoneDashFormatAll(lv_Celno)
      }

      return lv_Celno
    },
    /*********************************************************
     * 전화번호 형식 format(8자리)
     * 예) 1234-5678
     *
     * value: 전화번호 형식으로 변경할 String
     *********************************************************/
    fn_PhoneDashFormat (value) {
      if (!value) return ''
      value = value.toString().replace(/-/gi, '')

      if (value.length > 8) {
        value = value.substr(0, 8)
        value = this.$commonUtil.insertString(value, 4, '-')
      } else if (value.length === 8) {
        value = this.$commonUtil.insertString(value, 4, '-')
      } else if (value.length > 3) {
        value = this.$commonUtil.insertString(value, 3, '-')
      }
      return value
    },
    /*********************************************************
     * 이통사 포함 전화번호 형식 format(10자리 or 11자리)
     * 예) 010-1234-5678
     *
     * value: 전화번호 형식으로 변경할 String
     *********************************************************/
    fn_PhoneDashFormatAll (value) {
      if (!value) return ''
      value = value.toString().replace(/-/gi, '')

      if (value.length >= 11) {
        value = value.substr(0, 11)
        value = this.$commonUtil.insertString(value, 7, '-')
        value = this.$commonUtil.insertString(value, 3, '-')
      } else if (value.length > 7) {
        value = this.$commonUtil.insertString(value, 6, '-')
        value = this.$commonUtil.insertString(value, 3, '-')
      } else if (value.length > 3) {
        value = this.$commonUtil.insertString(value, 3, '-')
      }
      return value
    },
    /*********************************************************
     * Function명: fn_OpenMSPCM014P
     * 설명 : 추가정보입력(주소: 집, 회사)
     *
     *********************************************************/
    fn_OpenMSPCM014P (){
      let lv_Vm = this

      lv_Vm.popup014 = lv_Vm.$bottomModal.open(MSPCM014P, {
        properties: {
          pCustCardVO : lv_Vm.custCardVO,
          pAddrType : lv_Vm.addrType
        },
        listeners: {
          onPopupCancel: () => {
            lv_Vm.$bottomModal.close(lv_Vm.popup014)
            this.useMSPCM014P = false
          },
          onPopupClose: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.popup014)
            this.useMSPCM014P = true

            // 파라미터 세팅
            lv_Vm.fn_ConfirmMSPCM014P(pData)
          }
        }
      })
    },
    /*********************************************************
     * Function명: fn_ConfirmMSPCM014P
     * 설명 : 주소 검색 팝업창 호출 결과
     *
     *********************************************************/
    fn_ConfirmMSPCM014P (item){
      // 주소검색팝업에서 (자택, 주소) 선택만 수정에서 둘다 저장 될 수 있도록 수정 2022-07-26
      // 주소 입력값 초기화
      this.custCardVO.homAddrScCd = ''
      this.custCardVO.homAddr = ''
      this.custCardVO.homDtlad = ''
      this.custCardVO.bldgNo01 = ''
      this.custCardVO.adtnAddr01 = ''
      this.custCardVO.homFrnoPstcd = ''
      this.custCardVO.homAddrRefnCd = ''
      this.custCardVO.homAddrStatCd = ''

      this.addrType = item.addrType
      if( !this.$bizUtil.isEmpty(item.resCustCardVO.homAddr)){
        this.custCardVO.homAddrScCd = item.resCustCardVO.homAddrScCd
        this.custCardVO.homAddr = item.resCustCardVO.homAddr // '서울 동작구 상도로55길'
        this.custCardVO.homDtlad = item.resCustCardVO.homDtlad // '201동 201호'
        this.custCardVO.bldgNo01 = item.resCustCardVO.bldgNo01 // '47'
        this.custCardVO.adtnAddr01 = item.resCustCardVO.adtnAddr01 // '(상도동,래미안상도2차아파트)'
        this.custCardVO.homFrnoPstcd = item.resCustCardVO.homFrnoPstcd // '06978'
        this.custCardVO.homAddrRefnCd = item.resCustCardVO.homAddrRefnCd // 직장주소정제코드(01: 정제주소, 02:입력주소)
        this.custCardVO.homAddrStatCd = item.resCustCardVO.homAddrStatCd // 집주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
      }

      this.custCardVO.jobpAddrScCd = ''
      this.custCardVO.jobpAddr = ''
      this.custCardVO.jobpDtlad = ''
      this.custCardVO.bldgNo02 = ''
      this.custCardVO.adtnAddr02 = ''
      this.custCardVO.jobpFrnoPstcd = ''
      this.custCardVO.jobpAddrRefnCd = ''
      this.custCardVO.jobpAddrStatCd = ''

      if( !this.$bizUtil.isEmpty(item.resCustCardVO.jobpAddr)){
        this.custCardVO.jobpAddrScCd = item.resCustCardVO.jobpAddrScCd
        this.custCardVO.jobpAddr = item.resCustCardVO.jobpAddr // '서울 동작구 상도로55길'
        this.custCardVO.jobpDtlad = item.resCustCardVO.jobpDtlad // '201동 201호'
        this.custCardVO.bldgNo02 = item.resCustCardVO.bldgNo02 // '47'
        this.custCardVO.adtnAddr02 = item.resCustCardVO.adtnAddr02 // '(상도동,래미안상도2차아파트)'

        this.custCardVO.jobpFrnoPstcd = item.resCustCardVO.jobpFrnoPstcd // 우편번호 '06978'
        this.custCardVO.jobpAddrRefnCd = item.resCustCardVO.jobpAddrRefnCd // '01' // 직장주소정제코드(01: 정제주소, 02:입력주소)
        this.custCardVO.jobpAddrStatCd = item.resCustCardVO.jobpAddrStatCd // 직장주소상태코드 (11:정상, 12:오류, 13:반송(생명특화))
      }

      // 기존 고객의 변경인 경우 true로 처리
      this.custCardVO.isAddrChanged = item.resCustCardVO.isAddrChanged
    },
    /*********************************************************
     * Function명: fn_OpenMSPCM015P
     * 설명 : 외국인 등록 정보 등록 팝업 열기
     *********************************************************/
    fn_OpenMSPCM015P (){
      let lv_Vm = this

      lv_Vm.popup015 = lv_Vm.$bottomModal.open(MSPCM015P, {
        properties: {
          title: '외국인정보', // 팝업의 타이틀 셋팅
          tempCustCardVo : lv_Vm.custCardVO
        },
        listeners: {
          confirmPopup: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.popup015)

            // 파라미터 세팅
            lv_Vm.fn_CountryCodePopRtn(pData)
          }
        }
      })
    },
    /*********************************************************
    * Function명: fn_OpenMSPCM016P
    * 설명 : 세대원 입력 팝업
    *********************************************************/
    fn_OpenMSPCM016P (){
      let lv_Vm = this
      lv_Vm.popup016 = lv_Vm.$bottomModal.open(MSPCM016P, {
        properties: {
          custCardVO: lv_Vm.custCardVO,
          infoPrcusCnsntTypCd: lv_Vm.infoPrcusCnsntTypCd,
          selcAgreeTypeMcnst: lv_Vm.selcAgreeTypeMcnst,
          selcAgreeTypeMkt: lv_Vm.selcAgreeTypeMkt,
          pExistHoshInfoListInqrVO: lv_Vm.existHoshInfoListInqrVO,
          pNewHoshInfoListInqrVO: lv_Vm.newHoshInfoListInqrVO
        },
        listeners: {
          onPopupCancel: () => {
            //close
            lv_Vm.$bottomModal.close(lv_Vm.popup016)
            //this.useMSPCM016P = false

          },
          onPopupConfirm: (pData) => {
            lv_Vm.$bottomModal.close(lv_Vm.popup016)
            //this.useMSPCM016P = true
            
            // 파라미터 세팅
            lv_Vm.fn_ConfirmMSPCM016P(pData)
          }
        }
      })
    },
    /*********************************************************
     * Function명: fn_ConfirmMSPCM016P
     * 설명 : 세대원 입력 완료 시 호출
     *
     *********************************************************/
    fn_ConfirmMSPCM016P (item){
      this.$addSnackbar("세대원 추가가 완료되었습니다.")
      this.existHoshInfoListInqrVO = this.$bizUtil.cloneDeep(item.existHoshInfoListInqrVO)
      this.newHoshInfoListInqrVO = this.$bizUtil.cloneDeep(item.newHoshInfoListInqrVO)

      this.iCHoshInfoList = [...this.existHoshInfoListInqrVO, ...this.newHoshInfoListInqrVO]

      this.iCHoshInfoList.forEach(item => {
        if (this.$bizUtil.isEmpty(item.ralDob)) {
          item.ralDob = item.knbFrno ? this.$bizUtil.getDotDateToFullDate(item.knbFrno) : ''
        }
      })
    },
    
    /******************************************************************************
    * Function명 : fn_PrcsConfirmMsg
    * 설명       : 변경 전 계약자 동의 띄우기 / 화면 기준 유효성 체크
    ******************************************************************************/
    fn_PrcsConfirmMsg () {
      let lv_RetVal = this.fn_ValidateBeforeSaveCust()
      if (lv_RetVal) {
        if (this.custCardVO.chnlCustScCd === '03' || this.custCardVO.chnlCustScCd === '04' || this.custCardVO.chnlCustScCd === '05') { 
          // 계약 고객이면서, 정보 변경시 알림창 띄우기
          this.fn_SaveBfCnfrmPop('Open')
        } else {
          //고객 연락처와 실제 고객 번호 안내 팝업
          this.fn_ConfirmOpen()
        }
      }
    },
    /******************************************************************************
    * Function명 : fn_ValidateBeforeSaveCust
    * 설명       : 저장버튼 클릭 시 유효성 체크
    ******************************************************************************/
    fn_ValidateBeforeSaveCust () {
      let lv_RetVal = true
      let Msg = ''
      let callbackFnc = null
      
      //직업
      if( (this.custCardVO.jobCd === undefined || this.custCardVO.jobCd.length === 0 )){
         Msg = '직업 선택은 필수입력 항목입니다.'
         lv_RetVal = false
      }//취득경로 (지인, 개척, 기타)
      else if(this.entryPath === undefined || this.entryPath.length === 0){
        if (this.custCardVO.coalCustYn !== 'Y') {
          Msg = '취득 경로는 필수입력 항목입니다.'
          lv_RetVal = false
        }
      }//취득경로 (소개)
      else if(this.entryPath === 'intdr' && this.$bizUtil.isEmpty(this.custCardVO.custNm01)){
        Msg = '취득 경로 소개는 소개자명 필수입력 항목입니다.'
        lv_RetVal = false
      }//취득경로 (소개) 소개자 조회 하지 않을 경우
      else if(this.entryPath === 'intdr' && (!this.$bizUtil.isEmpty(this.custCardVO.custNm01) && !this.intrResult)){
        Msg = '취득 경로 소개는 소개자명 입력 후, 조회가 필요합니다.'
        lv_RetVal = false
      } //취득경로 (소개) 소개일 비어있는 경우
      else if(this.entryPath === 'intdr' && !this.custCardVO.introYmd){
        Msg = '취득 경로 소개는 소개일 필수입력 항목입니다.'
        lv_RetVal = false
      } //주소
      else if (this.custCardVO.homFrnoPstcd === '00000') {
        Msg = '자택 주소정보를 확인해주세요.'
        lv_RetVal = false
      }
      else if (this.custCardVO.jobpFrnoPstcd === '00000') {
        Msg = '직장 주소정보를 확인해주세요.'
        lv_RetVal = false
      }

      // 기존 세대원 체크 2022-08-25
      let lv_LengthExist = this.existHoshInfoListInqrVO.length
      if (lv_LengthExist > 0) {
        for (var i = lv_LengthExist; i > 0; i--) {
          var lv_Indx = i - 1
          //필컨포함 시, 기존 세대원 임시고객 여부 체크
          if(this.infoPrcusCnsntTypCd !== '19' && this.existHoshInfoListInqrVO[lv_Indx].chnlCustScCd === '02'){
            let lv_KnbBkno = ''
            
            if (this.$commonUtil.isMobile()) {
              lv_KnbBkno = this.existHoshInfoListInqrVO[lv_Indx].knbBknoCipher
            } else {
              lv_KnbBkno = this.existHoshInfoListInqrVO[lv_Indx].knbBkno
            }

            if(this.$bizUtil.isEmpty(lv_KnbBkno)){
              Msg = '세대원 주민등록번호를 확인해주세요.('+this.existHoshInfoListInqrVO[lv_Indx].custNm+')'
              callbackFnc = this.fn_OpenMSPCM016P
              lv_RetVal = false
            }
          }
        }
      }

      if (Msg !== '') {
        this.fn_OpenAlert(Msg, callbackFnc)
      }

      return lv_RetVal
    },
    /******************************************************************************
    * Function명 : fn_ConfirmSaveBfCnfrmPop
    * 설명       : 저장 전 동의 후 호출되는 함수
    ******************************************************************************/
    fn_ConfirmSaveBfCnfrmPop () {
      this.fn_SetCustInfoBeforSaving() // 저장 전 주고객 정보 세팅
    },
    /******************************************************************************
    * Function명 : fn_SetHoshInfoBeforSaving
    * 설명       : 저장 전 VO에 담기 위한 세대원 정보 세팅
    ******************************************************************************/
    fn_SetHoshInfoBeforSaving () {
      let lv_Vm = this
      // 주고객카드VO 세대원 초기화
      lv_Vm.custCardVO.iCHoshInfoListInqrVO = null
      lv_Vm.custCardVO.iCHoshInfoListInqrVO = []

      // 기존세대원, 신규세대원 저장용 VO에 복사 - 화면에 보여주는 값과 별개로 세팅하기 위함
      lv_Vm.saveExistHoshInfoListInqrVO = JSON.parse(JSON.stringify(lv_Vm.existHoshInfoListInqrVO))
      lv_Vm.saveNewHoshInfoListInqrVO = JSON.parse(JSON.stringify(lv_Vm.newHoshInfoListInqrVO))

      // 기존고객 세대원 리스트
      lv_Vm.saveExistHoshInfoListInqrVO.forEach(
        (item) => {
          // 기존/신규 공통 세팅
          item.athntHstrId = lv_Vm.custCardVO.athntHstrId // 카카오톡인증이력ID
          item.lmsDspchRsltId = lv_Vm.custCardVO.lmsDspchRsltId // LMS발송결과ID
          item.infoPrcusCnsntTypCd = lv_Vm.infoPrcusCnsntTypCd // 정보활용동의유형코드

          // 동의유형에 따른 값 세팅
          if (lv_Vm.agreeTypeMcnstList.infoPrcusCnsntPathCd !== undefined) { // 필컨
            item.infoPrcusCnsntPathCd = lv_Vm.agreeTypeMcnstList.infoPrcusCnsntPathCd // 정보활용동의경로코드
          } else { // 마동
            item.infoPrcusCnsntPathCd = lv_Vm.agreeTypeMktList.infoPrcusCnsntPathCd // 정보활용동의경로코드
          }
          item.infoPrcusCnsntStatCd = '02' // 정보활용동의상태코드(01:신청, 02:승인, 03:승인취소)
          item.mktCnvsMthTelYn = (lv_Vm.agreeTypeMktList.mktCnvsMthTelYn !== undefined) ? lv_Vm.agreeTypeMktList.mktCnvsMthTelYn : 'N' // 마케팅권유방법전화여부
          item.mktCnvsMthSmsYn = (lv_Vm.agreeTypeMktList.mktCnvsMthSmsYn !== undefined) ? lv_Vm.agreeTypeMktList.mktCnvsMthSmsYn : 'N' // 마케팅권유방법SMS여부
          item.mktCnvsMthEmailYn = (lv_Vm.agreeTypeMktList.mktCnvsMthEmailYn !== undefined) ? lv_Vm.agreeTypeMktList.mktCnvsMthEmailYn : 'N' // 마케팅권유방법메일여부
          item.mktCnvsMthPstYn = (lv_Vm.agreeTypeMktList.mktCnvsMthPstYn !== undefined) ? lv_Vm.agreeTypeMktList.mktCnvsMthPstYn : 'N' // 마케팅권유방법우편여부
          item.mktCnsntAvlPrd = (lv_Vm.agreeTypeMktList.mktCnsntAvlPrd !== undefined) ? lv_Vm.agreeTypeMktList.mktCnsntAvlPrd : '' // 마케팅동의유효기간

          let temp_knb = item.knb // 조회된 주민번호 셋팅
          item.prextKnb = item.prextKnb // 주민번호 암호화 셋팅

          // 주민등록번호 / 채널고객구분코드 세팅
          if (lv_Vm.infoPrcusCnsntTypCd === '19') { // 마케팅동의만 선택 시, 조회된 주민등록번호
            item.knbFrno = temp_knb.substring(0, 6)
            item.knbBkno = temp_knb.substring(6)
            item.knbBknoCipher = '' // 뒷자리암호화필드 공백처리
            item.chnlCustScCd = item.chnlCustScCd
          } else { // 필수컨설팅 동의 포함 선택
            // 임시고객이었는데 필컨 동의하면 가망고객(01)으로 보내야 함. (01,03,04,05 고객은 기존유지)
            // if (item.isKnbChanged === true) { => 모바일에서 안걸림
            if (temp_knb.substring(7) === '000000') { // 기존에 임시고객이어서 주민번호 직접입력O, 입력한 주민번호 셋팅
              if (item.chnlCustScCd === '' || item.chnlCustScCd === '02') {
                item.chnlCustScCd = '01'
              }
              item.knb = item.knb // 사랑온에서 입력한 주민등록번호
              item.knbFrno = item.knbFrno
              if (lv_Vm.$commonUtil.isMobile()) {
                item.knbBknoCipher = item.knbBknoCipher
              } else {
                item.knbBkno = item.knbBkno
              }
            } else { // 기존-가망이어서 주민번호 직접입력X, 받은그대로 셋팅
              if (item.chnlCustScCd === '' || item.chnlCustScCd === '02') {
                item.chnlCustScCd = '01'
              }
              item.knbFrno = temp_knb.substring(0, 6)
              item.knbBkno = temp_knb.substring(6)
              // item.knbBknoCipher = '' // 뒷자리암호화필드 공백처리
            }
          }

          // F1MDI0372 조회해서 받은값으로 넣어줘야하는 필드 세팅
          item.chnlCustId = item.chnlCustId // 채널고객ID
          item.afbfFlag = item.afbfFlag // 승인전 승인후 구분
          item.custId = item.custId // 고객ID (BP ID)
          item.chnlCstgrId = item.chnlCstgrId // 주고객관계그룹ID
        }
      )

      // 주고객 세대원LIST에 기존세대원LIST 추가
      for (let i = 0; i < this.saveExistHoshInfoListInqrVO.length; i++) {
        this.custCardVO.iCHoshInfoListInqrVO.push(this.$bizUtil.cloneDeep(this.saveExistHoshInfoListInqrVO[i]))
      }

      // 신규고객 세대원 리스트
      lv_Vm.saveNewHoshInfoListInqrVO.forEach(
        (item) => {
          // 기존/신규 공통 세팅
          item.athntHstrId = lv_Vm.custCardVO.athntHstrId // 카카오톡인증이력ID
          item.lmsDspchRsltId = lv_Vm.custCardVO.lmsDspchRsltId // LMS발송결과ID
          item.infoPrcusCnsntTypCd = lv_Vm.infoPrcusCnsntTypCd // 정보활용동의유형코드

          // 동의유형에 따른 값 세팅
          if (lv_Vm.agreeTypeMcnstList.infoPrcusCnsntPathCd !== undefined) { // 필컨
            item.infoPrcusCnsntPathCd = lv_Vm.agreeTypeMcnstList.infoPrcusCnsntPathCd // 정보활용동의경로코드
          } else { // 마동
            item.infoPrcusCnsntPathCd = lv_Vm.agreeTypeMktList.infoPrcusCnsntPathCd // 정보활용동의경로코드
          }
          item.infoPrcusCnsntStatCd = '02' // 정보활용동의상태코드(01:신청, 02:승인, 03:승인취소)
          item.mktCnvsMthTelYn = (lv_Vm.agreeTypeMktList.mktCnvsMthTelYn !== undefined) ? lv_Vm.agreeTypeMktList.mktCnvsMthTelYn : 'N' // 마케팅권유방법전화여부
          item.mktCnvsMthSmsYn = (lv_Vm.agreeTypeMktList.mktCnvsMthSmsYn !== undefined) ? lv_Vm.agreeTypeMktList.mktCnvsMthSmsYn : 'N' // 마케팅권유방법SMS여부
          item.mktCnvsMthEmailYn = (lv_Vm.agreeTypeMktList.mktCnvsMthEmailYn !== undefined) ? lv_Vm.agreeTypeMktList.mktCnvsMthEmailYn : 'N' // 마케팅권유방법메일여부
          item.mktCnvsMthPstYn = (lv_Vm.agreeTypeMktList.mktCnvsMthPstYn !== undefined) ? lv_Vm.agreeTypeMktList.mktCnvsMthPstYn : 'N' // 마케팅권유방법우편여부
          item.mktCnsntAvlPrd = (lv_Vm.agreeTypeMktList.mktCnsntAvlPrd !== undefined) ? lv_Vm.agreeTypeMktList.mktCnsntAvlPrd : '' // 마케팅동의유효기간

          // 채널고객ID 초기화
          item.chnlCustId = ''
          item.afbfFlag = 'B'
          // 주민등록번호 / 채널고객구분코드 세팅
          if (lv_Vm.infoPrcusCnsntTypCd === '19') { // 마케팅동의만 선택 시 임시고객 (주민번호미확보)
            item.knbFrno = item.knbFrno
            item.knbBkno = item.knbBkFstNo + '000000' // 성별코드 + 000000 값
            item.knbBknoCipher = '' // 뒷자리암호화필드 공백처리
            item.chnlCustScCd = '02'
          } else { // 필수컨설팅 동의 포함 선택 시 가망고객 (주민번호확보)
            item.knbFrno = item.knbFrno
            if (this.$commonUtil.isMobile()) {
              item.knbBknoCipher = item.knbBknoCipher
            } else {
              item.knbBkno = item.knbBkno
            }
            item.chnlCustScCd = '01'
          }

          // 생년월일 구하기(주민번호 앞자리에서 취득)
          // item.dob = lv_Vm.$bizUtil.addBirthFrontYear(item.knbFrno)

          // 실제 생일
          // item.ralBrthd = lv_Vm.$bizUtil.addBirthFrontYear(item.knbFrno)

          // 실명확인결과코드
          if ( this.$bizUtil.isEmpty(item.rlnmCnfRsltCd) ) {
            item.rlnmCnfRsltCd = ''
          } else if (item.custNm === '태아' && item.knbFrno === '000000') {
            item.rlnmCnfRsltCd = ''
          }
        }
      ) // forEach 끝

      // 주고객 세대원LIST에 신규세대원LIST 추가
      for (let i = 0; i < this.saveNewHoshInfoListInqrVO.length; i++) {
        this.custCardVO.iCHoshInfoListInqrVO.push(this.$bizUtil.cloneDeep(this.saveNewHoshInfoListInqrVO[i]))
      }
      this.fn_SaveCustomer('SAVE')
    },
    /******************************************************************************
    * Function명 : fn_SaveCustomer
    * 설명       : 고객 저장 (주고객+세대원)
    ******************************************************************************/
    fn_SaveCustomer (chk) {
      let lv_Vm = this
      lv_Vm.isLoading = true
      lv_Vm.custCardVO.saveMode = chk // 세대원만 추가할 경우 'CHECK'값 셋팅

      // let lv_CustCardVO = this.$bizUtil.cloneDeep(this.custCardVO) // 고객카드VO 복사 - 소개일의 날짜 포맷을 yyyymmdd로 변경함.
      let lv_CustCardVO = JSON.parse(JSON.stringify(this.custCardVO))

      // 취득경로가 소개인 경우 YYYYMMDD로 변경. 그 외에는 소개자 항목은 초기화 함.
      if (lv_Vm.entryPath.length > 0 && lv_Vm.entryPath === 'intdr') {
        lv_CustCardVO.introYmd = moment(lv_CustCardVO.introYmd).format('YYYYMMDD')
      } else {
        lv_CustCardVO.introYmd = ''
        lv_CustCardVO.custNm01 = ''
        lv_CustCardVO.intdrChnlCustId = ''
      }

      let pParams = lv_CustCardVO
      pParams.cnsltNo = this.getStore('userInfo').getters.getUserInfo.userId
      // GA 매니저 대행 컨설턴트 변경시
      if(lv_Vm.$cmUtil.fn_GachangeTF()){
        pParams.cnsltNo = lv_Vm.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo
      }
	  
      //매니저가 컨설턴트를 변경했을경우
      lv_Vm.stndKeyList = []
      if(lv_Vm.$cmUtil.fn_GachangeTF()){
        lv_Vm.stndKeyList.push({stndKeyId: 'cnsltNo', stndKeyVal: lv_Vm.getStore('cmStore').getters.getState.gssEplyInfo.eplyNo})
      }

      pParams.prextKnbScCd = this.$commonUtil.getIsIOS() ? 'IOS' : ''
      let trnstId = 'txTSSCM10I5'

      this.post(lv_Vm, pParams, trnstId, 'I')
        .then(function (response) {
          // 서버 데이터 가져오기
          if (response.body !== null) {
            lv_Vm.custCardVO.introYmd = lv_Vm.$commonUtil.dateDashFormat(lv_Vm.custCardVO.introYmd) // 소개일자 포맷 원복

            if (response.body.usePsbYn !== undefined && response.body.usePsbYn === 'E') { // 실명인증 실패 시
              lv_Vm.custCardVO.blckCustNm = response.body.blckCustNm
              lv_Vm.custCardVO.trtRsltCntnt = response.body.trtRsltCntnt
              // 실명인증실패 메시지 처리
              // lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.fn_GetNiceAuthResultMsg(lv_Vm.custCardVO.blckCustNm, lv_Vm.custCardVO.trtRsltCntnt))
              lv_Vm.niceAuthResultMsg = lv_Vm.fn_GetNiceAuthResultMsg(lv_Vm.custCardVO.blckCustNm, lv_Vm.custCardVO.trtRsltCntnt)
              lv_Vm.fn_NiceAuthPop('Open')
            } else if (response.body.usePsbYn !== undefined && response.body.usePsbYn === 'W') { // 동의 3개월 블로킹 시, 세대원만 저장
              let trtRsltCntnt = response.body.trtRsltCntnt.split('-')
              lv_Vm.lv_msgAddHoshOnly = response.body.blckCustNm + ' 고객님은 ' + lv_Vm.$commonUtil.dateDashFormat(trtRsltCntnt[2].trim()) + ' 에 동의를 받아 추가 동의는 불가합니다.'
              
              if (lv_Vm.custCardVO.iCHoshInfoListInqrVO.length > 0) {
                lv_Vm.lv_msgAddHoshOnly += '\n 세대원에 대한 동의만 진행하시겠습니까?'
                lv_Vm.fn_ConfirmHsmbCnsntOpen(lv_Vm.lv_msgAddHoshOnly)
              } else {
                lv_Vm.getStore('confirm').dispatch('ADD', lv_Vm.lv_msgAddHoshOnly)
              }
            } else { // 실명인증성공 및 저장완료 시
              // 주고객 리턴값 세팅
              lv_Vm.custCardVO.chnlCustId = response.body.chnlCustId
              lv_Vm.custCardVO.custNm = response.body.custNm
              lv_Vm.custCardVO.knb = response.body.knb
              lv_Vm.custCardVO.mnCustRltnCd = response.body.mnCustRltnCd
              lv_Vm.custCardVO.infoPrcusCnsntTypCd = response.body.infoPrcusCnsntTypCd

              if (response.body.iCHoshInfoListInqrVO != null) {
                // 세대원 리턴값 세팅
                for (let i = 0; i < lv_Vm.custCardVO.iCHoshInfoListInqrVO.length; i++) {
                  response.body.iCHoshInfoListInqrVO.forEach(
                    (value) => {
                      if (value.custNm === lv_Vm.custCardVO.iCHoshInfoListInqrVO[i].custNm.trim() && value.knb.substring(0, 6) === lv_Vm.custCardVO.iCHoshInfoListInqrVO[i].knbFrno) {
                        lv_Vm.custCardVO.iCHoshInfoListInqrVO[i].chnlCustId = value.chnlCustId
                        lv_Vm.custCardVO.iCHoshInfoListInqrVO[i].custNm = value.custNm
                        lv_Vm.custCardVO.iCHoshInfoListInqrVO[i].knb = value.knb
                        lv_Vm.custCardVO.iCHoshInfoListInqrVO[i].mnCustRltnCd = value.mnCustRltnCd
                        lv_Vm.custCardVO.iCHoshInfoListInqrVO[i].infoPrcusCnsntTypCd = response.body.infoPrcusCnsntTypCd
                      }
                    }
                  )
                }
              }
              //페이지 없어 주석처리
              lv_Vm.fn_MoveMspcm019mPage() //동의완료화면으로 이동
            }
          } else {
            // lv_Vm.disabledYN.isExistCustDisabd = false // 고객카드조회 버튼 활성화
            lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
          }

          lv_Vm.isLoading = false
        })
        .catch(function (error) {
          window.vue.error(error)
        })
    },
    /******************************************************************************
    * Function명 : fn_MoveMspcm019mPage
    * 설명       : MSPCM019M화면으로 이동 (동의완료)
    ******************************************************************************/
    fn_MoveMspcm019mPage () {
      // Store 페이지 카운트
      let pageCnt = window.vue.getStore('cmStore').getters.getState.viewCntCM050M
      window.vue.getStore('cmStore').dispatch('VIEW_CNT_CM050M', pageCnt+1)

      let storeCustInfo = window.vue.getStore('cmStore').getters.getState.custInfo
      // 필동 정보 초기화
      storeCustInfo.infoPrcusCnsntTypCd = ''
      storeCustInfo.agreeTypeMcnst = false
      // 마동 정보 초기화
      storeCustInfo.mktChkList = ''
      storeCustInfo.segmentType1 = ''
      storeCustInfo.mktCnsntAvlPrd = ''

      window.vue.getStore('cmStore').dispatch('ADD', storeCustInfo)
      this.$router.push(
        {
          name: 'MSPCM019M',
          params: {
            name: 'MSPCM012M',
            custCardVO: this.custCardVO
          }
        }
      )
    },
    /******************************************************************************
    * Function명 : fn_SetCustInfoBeforSaving
    * 설명       : 저장 전 VO에 담기 위한 주고객 정보 세팅
    ******************************************************************************/
    fn_SetCustInfoBeforSaving () {
      // 기존/신규 공통 세팅
      this.custCardVO.athntHstrId = this.custCardVO.athntHstrId // 카카오톡인증이력ID
      this.custCardVO.lmsDspchRsltId = this.custCardVO.lmsDspchRsltId // LMS발송결과ID
      this.custCardVO.infoPrcusCnsntTypCd = this.infoPrcusCnsntTypCd // 정보활용동의유형코드
      this.custCardVO.trtSystmPathCd = '22' // 처리시스템경로코드 (사랑온 고정 구분값 : 22)
      this.custCardVO.busnTrtPathCd = '2220' // 업무처리경로코드 (태블릿_사랑온고객관리 : 2220)
      this.custCardVO.trtSystmPathDtlVal = 'MSPCM012M' // 처리시스템경로상세값 (EP에서 화면ID로 요청)

      this.custCardVO.custJobCd = this.custCardVO.jobCd // 직업코드 레이아웃에 맞춰서 세팅
      this.custCardVO.custJobCdNm = this.custCardVO.jobCdNm // 직업명 레이아웃에 맞춰서 세팅

      // 동의유형에 따른 값 세팅
      if (this.agreeTypeMcnstList.infoPrcusCnsntPathCd !== undefined) { // 필컨
        this.custCardVO.infoPrcusCnsntPathCd = this.agreeTypeMcnstList.infoPrcusCnsntPathCd // 정보활용동의경로코드
      } else { // 마동
        this.custCardVO.infoPrcusCnsntPathCd = this.agreeTypeMktList.infoPrcusCnsntPathCd // 정보활용동의경로코드
      }
      this.custCardVO.infoPrcusCnsntStatCd = '02' // 정보활용동의상태코드(01:신청, 02:승인, 03:승인취소)
      this.custCardVO.mktCnvsMthTelYn = (this.agreeTypeMktList.mktCnvsMthTelYn !== undefined) ? this.agreeTypeMktList.mktCnvsMthTelYn : 'N' // 마케팅권유방법전화여부
      this.custCardVO.mktCnvsMthSmsYn = (this.agreeTypeMktList.mktCnvsMthSmsYn !== undefined) ? this.agreeTypeMktList.mktCnvsMthSmsYn : 'N' // 마케팅권유방법SMS여부
      this.custCardVO.mktCnvsMthEmailYn = (this.agreeTypeMktList.mktCnvsMthEmailYn !== undefined) ? this.agreeTypeMktList.mktCnvsMthEmailYn : 'N' // 마케팅권유방법메일여부
      this.custCardVO.mktCnvsMthPstYn = (this.agreeTypeMktList.mktCnvsMthPstYn !== undefined) ? this.agreeTypeMktList.mktCnvsMthPstYn : 'N' // 마케팅권유방법우편여부
      this.custCardVO.mktCnsntAvlPrd = (this.agreeTypeMktList.mktCnsntAvlPrd !== undefined) ? this.agreeTypeMktList.mktCnsntAvlPrd : '' // 마케팅동의유효기간

      // 고객명 / 휴대폰번호 는 사랑온 화면에서 입력한 값으로 보내야함
      this.custCardVO.custNm = this.custCardVO.custNm.trim() // 고객명
      // 핸드폰번호 '-' 추가
      let lv_Celno = this.custCardVO.celno.trim()

      this.custCardVO.celno = this.fn_FullPhoneDashFormat(lv_Celno, this.custCardVO.celnoFrno, this.custCardVO.celnoBkno)

      // 기존고객인 경우 - 고객카드조회(txTSSCM13S1, F1MDI0372) 서비스 조회 데이터에서 받은대로 필수 세팅
      if (this.searchRsltMsg === '기존고객') {
        if (this.mnCustCardRegMdfcVO !== null) {
          if (this.custCardVO.homFrnoPstcd.trim() !== '') {
            this.custCardVO.homPstcd = this.custCardVO.homFrnoPstcd // 집우편번호코드 레이아웃에 맞춰서 세팅
          }
          if (this.custCardVO.jobpFrnoPstcd.trim() !== '') {
            this.custCardVO.jobpPstcdSno = this.custCardVO.jobpFrnoPstcd // 직장우편번호코드 레이아웃에 맞춰서 세팅
          }

          let temp_knb = this.mnCustCardRegMdfcVO.knb // 조회된 주민번호 세팅

          // 주민등록번호 / 채널고객구분코드 세팅
          if (this.infoPrcusCnsntTypCd === '19') { // 마케팅동의만 선택 시, 조회된 주민등록번호
            this.custCardVO.knbFrno = temp_knb.substring(0, 6)
            this.custCardVO.knbBkno = temp_knb.substring(6)
            this.custCardVO.knbBknoCipher = '' // 뒷자리암호화필드 공백처리
            this.custCardVO.chnlCustScCd = this.mnCustCardRegMdfcVO.chnlCustScCd
          } else { // 필수컨설팅 동의 포함 선택
            // 임시고객이었는데 필컨 동의하면 가망고객(01)으로 보내야 함.
            this.custCardVO.chnlCustScCd = this.mnCustCardRegMdfcVO.chnlCustScCd
            if (this.custCardVO.chnlCustScCd === '' || this.custCardVO.chnlCustScCd === '02') {
              this.custCardVO.chnlCustScCd = '01'
            } else {
              this.custCardVO.knbFrno = temp_knb.substring(0, 6)
              this.custCardVO.knbBkno = temp_knb.substring(6)
            }
          }

          // F1MDI0372 조회해서 받은값으로 넣어줘야하는 필드 세팅
          this.custCardVO.chnlCustId = this.mnCustCardRegMdfcVO.chnlCustId // 채널고객ID
          this.custCardVO.afbfFlag = this.mnCustCardRegMdfcVO.afbfFlag // 승인전 승인후 구분
          this.custCardVO.custId = this.mnCustCardRegMdfcVO.custId // 고객ID (BP ID)
          this.custCardVO.chnlCstgrId = this.mnCustCardRegMdfcVO.chnlCstgrId // 주고객관계그룹ID
        }
        // 제휴고객여부 세팅
        if(this.disabledYN.entryDisabd) {
          this.entryPath = ''
          this.custCardVO.coalCustYn = this.mnCustCardRegMdfcVO.coalCustYn
        } else {
          // 취득경로 셋팅
          this.custCardVO.acutnCustYn = this.entryPath === 'acutnCustYn' ? 'Y' : 'N'
          this.custCardVO.intdr = this.entryPath === 'intdr' ? 'Y' : 'N'
          this.custCardVO.aptCustYn = this.entryPath === 'aptCustYn' ? 'Y' : 'N'
          this.custCardVO.rspnDstrtCustYn = this.entryPath === 'rspnDstrtCustYn' ? 'Y' : 'N'
        }
      } else { // 신규고객일때
        // 승인전 승인후 구분코드 세팅 - 신규고객 디폴트 'B'
        this.custCardVO.afbfFlag = 'B'
        this.custCardVO.homPstcd = this.custCardVO.homFrnoPstcd // 집우편번호코드 레이아웃에 맞춰서 세팅
        this.custCardVO.jobpPstcdSno = this.custCardVO.jobpFrnoPstcd // 직장우편번호코드 레이아웃에 맞춰서 세팅

        // 주민등록번호 / 채널고객구분코드 세팅
        if (this.infoPrcusCnsntTypCd === '19') { // 마케팅동의만 선택 시 임시고객 (주민번호미확보)
          this.custCardVO.knbFrno = this.custCardVO.knbFrno
          this.custCardVO.knbBkno = this.custCardVO.knbBknoBackup // 성별코드 + 000000 값
          this.custCardVO.knbBknoCipher = '' // 뒷자리암호화필드 공백처리
          this.custCardVO.chnlCustScCd = '02'
        } else { // 필수컨설팅 동의 포함 선택 시 가망고객 (주민번호확보)
          this.custCardVO.knbFrno = this.custCardVO.knbFrno
          if (this.$commonUtil.isMobile()) {
            this.custCardVO.knbBknoCipher = this.custCardVO.knbBknoCipher
          } else {
            this.custCardVO.knbBkno = this.custCardVO.knbBkno
          }
          this.custCardVO.chnlCustScCd = '01'
        }
        // 주고객관계코드 '01(본인)' 으로 세팅
        this.custCardVO.mnCustRltnCd = '01'

        // 식별번호 구분코드 세팅
        if (this.isForeigner === true) {
          this.custCardVO.knbScCd = 'ZPER02' // 외국인
        } else {
          this.custCardVO.knbScCd = 'ZPER01' // 내국인
        }
        // 제휴고객여부 세팅 - 사랑온에서는 선택 불가하므로 신규는 무조건 N으로 넘김
        this.custCardVO.coalCustYn = 'N'
      }
      this.fn_SetHoshInfoBeforSaving()
    },
    /*********************************************************
     *Function명 : fn_CallCmplt
     *설명       : 완료 버튼 클릭 시 실행
     *********************************************************/
    fn_CallCmplt (param) {
      //this.btnCaller === 'NEXT'
      this.fn_PrcsConfirmMsg()
      
    },
    /******************************************************************************
     * Function명 : fn_ConfirmOpen
     * 설명       : confirm창 오픈 함수
     *              confirm 창이 열릴경우 vuex에 열림 표시를 저장한다.
     ******************************************************************************/
    fn_ConfirmOpen () {
      let contentMsg = '고객 연락처와 실제 고객 번호가<br/> 맞는지 확인하셨습니까?<br/> 임의번호 입력 금지<br/>(예, 010-xxxx-0000, 010-xxxx-1111)'
      
      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            //single: true,
            title: "",
            content: contentMsg,
            title_pos_btn: "확인",
            title_neg_btn: "취소"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              this.fn_ConfirmSaveBfCnfrmPop()
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
    /******************************************************************************
     * Function명 : fn_ConfirmAddHoshOpen
     * 설명       : confirm창 오픈 함수
     *              주고객(법정대리인)에 동의 안내
     ******************************************************************************/
    fn_ConfirmAddHoshOpen () {
      let contentMsg = ''
      if (this.custCardVO.mnCustRltnCd === '01' || this.custCardVO.mnCustRltnCd === '02') {
        contentMsg = '앞서 주고객(법정대리인)에 동의해주신 항목에 대해, 세대원도 동일하게 적용하시겠습니까?'

        let lv_AlertPopAddHosh = this.$bottomModal.open(MSPBottomAlert, {
            properties: {
              //single: true,
              title: "",
              content: contentMsg,
              title_pos_btn: "예",
              title_neg_btn: "아니요"
            },
            listeners: {
              onPopupConfirm: () => {
                this.$bottomModal.close(lv_AlertPopAddHosh)
                this.fn_OpenMSPCM016P()
              },
              onPopupClose: () => {
                this.$bottomModal.close(lv_AlertPopAddHosh)
              }
            }
          })
      } else {
        contentMsg = "세대원 추가는 '본인', '배우자'만 가능합니다.<br>고객카드에서 세대조정 후 진행 부탁드립니다."
        this.fn_OpenAlert(contentMsg)
      }

      
    },
    /******************************************************************************
     * Function명 : fn_ConfirmHsmbCnsntOpen
     * 설명       : 세대원 동의 안내
     ******************************************************************************/
    fn_ConfirmHsmbCnsntOpen (contentMsg) {

      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            //single: true,
            title: "",
            content: contentMsg,
            title_pos_btn: "확인",
            title_neg_btn: "취소"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              this.fn_SaveCustomer('CHECK')
            },
            onPopupClose: () => {
              this.$bottomModal.close(lv_AlertPop)
            }
          }
        })
    },
    /******************************************************************************
     * Function명 : fn_OpenAlert
     * 설명       : 알럿 호출 함수
     ******************************************************************************/
    fn_OpenAlert (alertMsg, callbackFnc) {

      let lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            single: true,
            title: "",
            content: alertMsg,
            title_pos_btn: "확인",
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(lv_AlertPop)
              if (typeof(callbackFnc) === 'function') callbackFnc()
            }
          }
        })
    },
    /******************************************************************************
    * Function명 : fn_CheckSsnValidation
    * 설명       : 주민번호 유효성 체크
    ******************************************************************************/
    fn_CheckSsnValidation () {
      let lv_TmpValidation = false //주민번호(외국인등록번호) 유무 체크

      if (this.$commonUtil.isMobile()) {
        if (this.custCardVO.knbBknoCipher !== null && this.custCardVO.knbBknoCipher.trim() !== '') {
          lv_TmpValidation = true
        }
      } else {
        if (this.custCardVO.knbBkno !== null && this.custCardVO.knbBkno.trim() !== '') {
          lv_TmpValidation = true
        }
      }
      // 주민번호 앞자리가 입력되었는지, 주민번호 뒷자리가 입력되었는지 확인 후, 유효성 체크
      if (!this.custCardVO.knbFrno !== null && this.custCardVO.knbFrno.trim() !== '' && lv_TmpValidation) {
        this.isLoading = true
        let lv_Vm = this

        let lv_Knb = (lv_Vm.$commonUtil.isMobile()) ? '' : lv_Vm.custCardVO.knb // PC일 경우 전체 평문 주민번호를 넘겨줌. 모바일일 경우 공백처리
        let encKey = this.$commonUtil.getIsIOS() ? 'IOS' : ''

        let pParams =
                      {
                        knb: lv_Knb, // 전체 주민번호
                        knbFrno: lv_Vm.custCardVO.knbFrno, // 주민번호 앞번호
                        knbBknoCipher: lv_Vm.custCardVO.knbBknoCipher, // 주민번호 뒷번호
                        KnbScCd: encKey
                      }

        const trnstId = 'txTSSCM10S7'
        const auth = 'S'
        this.post(lv_Vm, pParams, trnstId, auth)
          .then(function (response) {
            // 서버 데이터 가져오기
            if (response.body !== null) {
              if (response.body.ssnYn === 'Y') {
                lv_Vm.custCardVO.knbBackup = response.body.knb

                // 고객카드 조회하기 전에 타기때문에 채널구분코드 세팅 주석처리
                // if (lv_Vm.custCardVO.chnlCustScCd === '' || lv_Vm.custCardVO.chnlCustScCd === '02') {
                //   lv_Vm.custCardVO.chnlCustScCd = '01'
                // }

                // 주민번호 유형값 세팅
                let lv_KnbBkno = response.body.knb.substring(6, 7)
                if (lv_KnbBkno === '1' || lv_KnbBkno === '2' || lv_KnbBkno === '3' || lv_KnbBkno === '4') {
                  lv_Vm.custCardVO.knbScCd = 'ZPER01'
                } else if (lv_KnbBkno === '5' || lv_KnbBkno === '6' || lv_KnbBkno === '7' || lv_KnbBkno === '8') {
                  lv_Vm.custCardVO.knbScCd = 'ZPER02'
                } else {
                  lv_Vm.custCardVO.knbScCd = ''
                }

                lv_Vm.chkKnbResult = true // 주민번호 유효성 체크 결과
              }
            } else {
              if (!lv_Vm.chkRrn) { // 주민번호 뒷자리 입력 후, 미확보 체크 선택 시 focus out 이벤트로 주민번호 유효성 체크서비스 호출됨. 따라서,  메시지 띄우기 전 미확보 체크여부 재확인
                lv_Vm.knbErrorMsg = response.msgComm.msgDesc
                lv_Vm.chkKnbResult = false
              }
            }

            lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      }
    },
    /******************************************************************************
    * Function명 : fn_CheckAgreePossible
    * 설명       : 만 14세 이상인지 여부 확인 후, 동의 여부 선택 가능한지 체크 - 필컨,마동 체크 선택이나 주민번호 입력 시 체크함.
    ******************************************************************************/
    fn_CheckAgreePossible () {
      let lv_Msg = ''
      if (this.custCardVO.knbFrno !== '' && this.custCardVO.knbFrno.length === 6 && (this.custCardVO.knbBkno !== '' || this.custCardVO.knbBknoCipher !== '')) {
        let lv_CheckAge = this.$bizUtil.compareCurrentAge(this.custCardVO.knbFrno, 14)

        if (lv_CheckAge === false) {
          lv_Msg = '만 14세이상인 경우에만 모바일 동의가 가능합니다.'
          this.getStore('confirm').dispatch('ADD', lv_Msg)
        }
      }
      return lv_Msg
    },
    /******************************************************************************
    * Function명 : fn_SelCelnoCheck
    * 설명       : 핸드폰번호 유효성 체크(임시 번호, FC와 동일번호 체크)
    ******************************************************************************/
    fn_SelCelnoCheck (paramCelno) {
      let lv_CustCelno = this.custCardVO.celno
      if (paramCelno !== undefined && paramCelno !== null && paramCelno !== '') { // created 시점의 임시번호 호출
        lv_CustCelno = paramCelno
      }
      if (lv_CustCelno === '01000000000' || lv_CustCelno === '0100000000' || lv_CustCelno === '01010000000' || lv_CustCelno === '01020000000' || lv_CustCelno === '01030000000') {
        this.chkCelnoResult = false
        this.celnoErrorMsg = this.$t('cm')['ECMC067'] // '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
        return
      }

      if ((paramCelno !== undefined && paramCelno !== null && paramCelno !== '') || (this.custCardVO.isCelnoChanged && this.custCardVO.unAprvCustYn === 'N')) { // 임시번호 호출이나 기존 승인고객의 핸드폰변경이 있는경우 유효성 체크
        this.isLoading = true
        let lv_Vm = this

        // 고객 VO복사
        let lv_CustCardVO = Object.assign({}, lv_Vm.custCardVO)

        if (this.$commonUtil.isMobile()) {
          if (lv_CustCardVO.knbBknoCipher.length < 8) { // 모바일이고, 주민번호 변경 안한 케이스
            if (lv_CustCardVO.knbBknoCipher !== '1000000' && lv_CustCardVO.knbBknoCipher !== '2000000' &&
              lv_CustCardVO.knbBknoCipher !== '3000000' && lv_CustCardVO.knbBknoCipher !== '4000000') { // 주민번호 확보 고객은 knb 제거
              lv_CustCardVO.knb = ''
            }
            lv_CustCardVO.knbBknoCipher = '' // 주민번호 미변경
          }
        }

        lv_CustCardVO.celno = lv_CustCelno

        let pParams = lv_CustCardVO
        let trnstId = 'txTSSCM10S3'

        this.post(lv_Vm, pParams, trnstId, 'S')
          .then(function (response) {
            if (response.body !== null) {
              if (response.body.celnoStatCo === '00') { // 정상일 경우
                lv_Vm.chkCelnoResult = true
              } else {
                if (response.body.celnoStatCo === '01') { // 01: 임시등록 번호 (*화면에서 1차로 filtering함. 현재는 사용안함.)
                  lv_Vm.chkCelnoResult = false
                  lv_Vm.celnoErrorMsg = lv_Vm.$t('cm')['ECMC067'] // '임의번호는 입력할 수 없습니다.(예: 010-xxxx-0000, 010-xxxx-1111 등) 다시 확인 후 입력 바랍니다.'
                } else if (response.body.celnoStatCo === '02') { // 02: FC휴대번호와 동일한 경우
                  lv_Vm.chkCelnoResult = false
                  lv_Vm.celnoErrorMsg = lv_Vm.$t('cm')['ECMC068'] // '컨설턴트 핸드폰번호로 등록된 번호입니다.'
                }
              }
            } else {
              lv_Vm.getStore('confirm').dispatch('ADD', response.msgComm.msgDesc)
            }

            lv_Vm.isLoading = false
          })
          .catch(function (error) {
            window.vue.error(error)
          })
      }
    },
      /******************************************************************************
    * Function명 : fn_GetNiceAuthResultMsg
    * 설명       : NICE실명 인증 처리 결과 메시지 처리
    ******************************************************************************/
    fn_GetNiceAuthResultMsg (custNm, trtRsltCntnt) {
      let lv_Msg = ''
      
      if (trtRsltCntnt !== null && trtRsltCntnt.trim() !== '') {
        if (trtRsltCntnt === 'EICU038') {
          lv_Msg += this.$t('cm')['ECMC023'].replace('{0}', custNm) // custNm + '님은 유포털에 동일한 주민번호로 이미 고객이 등록되어 있어 추가 등록이 불가하므로 지점 문의 후 처리 바랍니다.'
        } else if (trtRsltCntnt === 'EICU039') {
          lv_Msg += this.$t('cm')['ECMC024'].replace('{0}', custNm) // custNm + '님은 유효종료 고객이므로, 지점에 유효종료 처리 요청바랍니다.'
        } else if (trtRsltCntnt === 'EICU040') {
          lv_Msg += this.$t('cm')['ECMC025'].replace('{0}', custNm) // custNm + '님의 동의 신청이 처리가 불가합니다.(EICU040).'
        } else if (trtRsltCntnt === 'EICU101' || trtRsltCntnt === 'EICU102' || trtRsltCntnt === 'EICU103' || trtRsltCntnt === 'EICU104' || trtRsltCntnt === 'EICU105') {
          if (trtRsltCntnt === 'EICU101') {
            lv_Msg += this.$t('cm')['ECMC026'].replace('{0}', custNm) // custNm + '님은 실명확인 결과 <br>본인이 아니므로 동의가 처리되지<br>않았습니다.'
          } else if (trtRsltCntnt === 'EICU102') {
            lv_Msg += this.$t('cm')['ECMC027'].replace('{0}', custNm) // custNm + '님의 실명확인 결과 고객님의<br>요청으로 실명인증이 차단되어 동의가<br>처리되지 않았습니다. 고객님께서 NICE<br>평가정보에 문의하시어 명의도용<br>차단 해제 후 처리 가능합니다.'
          } else if (trtRsltCntnt === 'EICU103') {
            lv_Msg += custNm + this.$t('cm')['ECMC075'] // custNm + '님은 실명정보가 없어<br>동의가 처리되지 않았습니다. NICE<br>평가정보에 정보가 없거나, 주민<br>번호를 잘 못 입력하신 경우입니다.<br>인적사항 재확인 바랍니다.'
          } else if (trtRsltCntnt === 'EICU104') {
            lv_Msg += custNm + this.$t('cm')['ECMC076'] // custNm + '님의 실명확인 결과<br>인적사항 비정상 정보입니다.<br>* 대상자 정보, 거주지 정보 불명확 등'
          } else if (trtRsltCntnt === 'EICU105') {
            lv_Msg += custNm + this.$t('cm')['ECMC077'] // custNm + '님의 실명확인 결과<br>타인에 의한 부정사용이 의심되어<br>실명인증이 차단되었고, 이 때문에<br>동의 요청이 처리되지 않았습니다.<br>일정 시간 경과 후 자동 차단 해지 됩니다.'
          }
          lv_Msg += '<br><br>' + this.$t('cm')['ECMC035'] // ※ 실명확인 결과 문의는 NICE<br>평가정보 고객센터(1600-1522)에<br>확인 바랍니다.
        } else if (trtRsltCntnt === 'EICU201' || trtRsltCntnt === 'EICU202' || trtRsltCntnt === 'EICU203' || trtRsltCntnt === 'EICU204') {
          lv_Msg += this.$t('cm')['ECMC029'].replace('{0}', custNm) // custNm + '님의 동의 신청이 완료되었습니다.'

          if (trtRsltCntnt === 'EICU201') {
            lv_Msg = lv_Msg + ' ' + this.$t('cm')['ECMC030'] // ' 실명확인 결과, 정상입니다.'
          } else if (trtRsltCntnt === 'EICU202') {
            lv_Msg = lv_Msg + ' ' + this.$t('cm')['ECMC031'] // ' 실명확인 결과, 만 14세 미만 미성년자로 확인되므로 다시 한번 고객님의 실명 확인 바랍니다.'
          } else if (trtRsltCntnt === 'EICU203') {
            lv_Msg = lv_Msg + ' ' + this.$t('cm')['ECMC032'] // ' 실명확인 결과, 비정상(불완전 or 대상자불명확 or 거주지불명확 or 외국인명상이)인점 참고 바랍니다.'
          } else if (trtRsltCntnt === 'EICU204') {
            lv_Msg = lv_Msg + ' ' + this.$t('cm')['ECMC033'] // ' NICE와 대외계통신 에러인 경우로 실명인증이 진행되지 않은점 참고 바랍니다.'
          }
          lv_Msg += '<br><br>' + this.$t('cm')['ECMC035'] // ※ 실명확인 결과 문의는 NICE<br>평가정보 고객센터(1600-1522)에<br>확인 바랍니다.
        } else {
          lv_Msg += trtRsltCntnt
        }

        return lv_Msg
      }
    },
    /******************************************************************************
    * Function명: fn_Back
    * 설명: 헤더 영역 Back Button 파라미터 넘기기
    * Params: N/A
    * Return: N/A
    ******************************************************************************/
    fn_Back () {
      this.fn_CloseBackCnfrmPop('Open')
    },
    /******************************************************************************
    * @function fn_DelAddr
    * @notice 주소 삭제 이벤트 함수
    * @param addrType (home: 자택, job: 직장)
    ******************************************************************************/
    fn_DelAddr(addrType) {
      if (addrType === 'home') {
        this.custCardVO.adtnAddr01 = ''
        this.custCardVO.bldgNo01 = ''
        this.custCardVO.homAddr = ''
        this.custCardVO.homAddrRefnCd = ''
        this.custCardVO.homAddrScCd = ''
        this.custCardVO.homAddrStatCd = ''
        this.custCardVO.homDtlad = ''
        this.custCardVO.homFrnoPstcd = ''
        this.custCardVO.homPstcd = ''
      } else if (addrType === 'job') {
        this.custCardVO.adtnAddr02 = ''
        this.custCardVO.bldgNo02 = ''
        this.custCardVO.jobpAddr = ''
        this.custCardVO.jobpAddrRefnCd = ''
        this.custCardVO.jobpAddrScCd = ''
        this.custCardVO.jobpAddrStatCd = ''
        this.custCardVO.jobpDtlad = ''
        this.custCardVO.jobpFrnoPstcd = ''
        this.custCardVO.jobpPstcdSno = ''
      }

      this.fn_CloseBottomSheet('addrPop')
    },
    /******************************************************************************
    * @function fn_DelHosh
    * @notice 세대원 목록에서 삭제 (화면에서만 삭제, 고객카드에서 삭제 아님)
    ******************************************************************************/
    fn_DelHosh() {
      this.fn_CloseBottomSheet('hoshPop')
      let item = this.selectHoshItem
      
      console.log('Selected Hosh Item... >>', item)
      // 기존 세대원에서 삭제
      this.existHoshInfoListInqrVO = this.existHoshInfoListInqrVO.filter(hoshItem => {
                              return hoshItem.chnlCustId !== item.chnlCustId
                            });
      // 신규 세대원에서 삭제
      this.newHoshInfoListInqrVO = this.newHoshInfoListInqrVO.filter(hoshItem => {
                              return hoshItem.chnlCustId !== item.chnlCustId
                            });

      this.iCHoshInfoList = [...this.existHoshInfoListInqrVO, ...this.newHoshInfoListInqrVO]
    },
    /******************************************************************************
     * @function fn_ConfirmOpen2
     * @notice   : 세대원 삭제를 위한 선택 세대원 저장
     ******************************************************************************/
    fn_ConfirmOpen2 (item) {
      this.selectHoshItem = item
      this.$refs.refDeltHoshConf.open()
    },
    /******************************************************************************
     * @function fn_ConfirmOpen3
     * @notice   : 주소 삭제를 위한 선택 주소타입 저장
     ******************************************************************************/
    fn_ConfirmOpen3 (addrType) {
      this.deleteAddrType = addrType
      this.$refs.refDeltAddrPop.open()
    },
    /******************************************************************************
     * @function fn_CloseBottomSheet
     * @notice   : BottomSheet Close Function
     *              param값에 따라 바텀시트 Close
     * @param    : 바텀시트 구분 값
     ******************************************************************************/
    fn_CloseBottomSheet(item) {
      switch (item) {
        case 'saveBfCnfrmPop': // 저장 전 동의 안내
          this.$refs.bottomSheetSaveBfCnfrm.close()
          break
        case 'backCnfrmPop': // 종료 안내
          this.$refs.bottomSheetBackCnfrm.close()
          break
        case 'hoshPop': // 세대원 삭제 안내
          this.$refs.refDeltHoshConf.close()
          break
        case 'cntryPop': // 국가정보 입력 권유
          this.$refs.bottomSheetCntryInfo.close()
          break
        case 'addrPop': // 주소 삭제
          this.$refs.refDeltAddrPop.close()
          break
      }
    }
  } // Methods End
}
</script>
